import React from "react";
import { Route, Routes } from "react-router-dom";
import UsersPage from "../../pages/users/UsersPage";
import UserDetailsPage from "../../pages/users/UserDetailsPage";
import UserGlobalPermissions from "../../components/users/UserGlobalPermissions/UserGlobalPermissions";
import UserCompanyPermissions from "../../components/users/UserCompanyPermissions/UserCompanyPermissions";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import { UserPermissions } from "../../store/slices/userDataSlice";
import UserCompanyPermissionData from "../../components/users/UserCompanyPermissionData/UserCompanyPermissionData";
import NotFoundPage from "../../pages/NotFoundPage";

const UsersRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_USER_LIST}
            isAnywhereRole={true}
          >
            <UsersPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path=":userId/details"
        element={
          <ProtectedByRoleRoute permission={UserPermissions.ROLE_USER_READ}>
            <UserDetailsPage />
          </ProtectedByRoleRoute>
        }
      >
        <Route
          path="global-security"
          element={
            <ProtectedByRoleRoute
              permission={UserPermissions.ROLE_USER_GLOBAL_PERM_READ}
            >
              <UserGlobalPermissions />
            </ProtectedByRoleRoute>
          }
        />

        <Route
          path="company-security"
          element={
            <ProtectedByRoleRoute
              permission={UserPermissions.ROLE_USER_COMPANIES_READ}
            >
              <UserCompanyPermissions />
            </ProtectedByRoleRoute>
          }
        >
          <Route
            path=":companyId"
            element={
              <ProtectedByRoleRoute
                permission={UserPermissions.ROLE_USER_COMPANY_PERM_READ}
                isCompanyRole={true}
              >
                <UserCompanyPermissionData />
              </ProtectedByRoleRoute>
            }
          />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default UsersRoutes;
