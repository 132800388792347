import React from "react";
import { Route, Routes } from "react-router-dom";
import SecurityPage from "../../pages/security/SecurityPage/SecurityPage";
import SecurityGroupDataPage from "../../pages/security/SecurityGroupDataPage/SecurityGroupDataPage";
import { UserPermissions } from "../../store/slices/userDataSlice";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import NotFoundPage from "../../pages/NotFoundPage";

const SecurityRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_PERM_GROUP_LIST}
          >
            <SecurityPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path="create"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_PERM_GROUP_CREATE}
          >
            <SecurityGroupDataPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path="edit/:securityGroupId"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_PERM_GROUP_UPDATE}
          >
            <SecurityGroupDataPage />
          </ProtectedByRoleRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default SecurityRoutes;
