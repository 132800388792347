import Navigation from "../common/Navigation";
import { useUserHasPermission } from "src/hooks/useUserHasPermission";
import { TNavigationItem } from "src/globalTypes";

export const TemplatesPageTabs = () => {
  const { hasDocumentTemplateListRole, hasCampaignTemplatesListRole } =
    useUserHasPermission({});

  const navigationItems = [
    hasDocumentTemplateListRole && {
      title: "Documents",
      path: "/templates/documents",
    },
    hasCampaignTemplatesListRole && {
      title: "Campaigns",
      path: "/templates/campaigns",
    },
  ].filter(Boolean) as TNavigationItem[];

  if (navigationItems.length === 0) {
    return null;
  }

  return <Navigation items={navigationItems} />;
};
