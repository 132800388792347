import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useEffect, useState } from "react";
import { TUserCompanyPermissions } from "../../../globalTypes";
import {
  addSecurityGroupToUserApi,
  deleteUserCompanyPermissionGroupApi,
  getUserCompanyPermissionsApi,
} from "../../../api/user.api";
import handleRequestError from "../../../utils/handleRequestError";
import { useUpdateGroupsList } from "../UserCompanyPermissions/UserCompanyPermissions";

const useGetCompanyPermissions = () => {
  const { userId, companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");
  const [companyPermissions, setCompanyPermissions] =
    useState<TUserCompanyPermissions | null>(null);
  const navigate = useNavigate();
  const updateCompanyGroupsList = useUpdateGroupsList();
  const { pathname } = useLocation();
  const isUsersPage = pathname.startsWith("/users");

  useEffect(() => {
    setIsInitialized(false);
    loadPermissions();
  }, [userId, companyId]);

  const loadPermissions = async () => {
    try {
      if (userId !== undefined && companyId !== undefined) {
        const { data } = await getUserCompanyPermissionsApi({
          userId,
          companyId,
        });

        setCompanyPermissions(data);
        setError("");
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      setIsInitialized(true);
    }
  };

  const addGroupAndUpdateList = async ({
    permissionsGroupId,
    userId,
    companyId,
  }: {
    userId: number | string;
    permissionsGroupId: number;
    companyId: number | string | null;
  }) => {
    await addSecurityGroupToUserApi({
      userId,
      companyId,
      permissionsGroupId,
    });
    await loadPermissions();
    updateCompanyGroupsList &&
      (await updateCompanyGroupsList({ userId, update: true }));
  };

  const deleteCompanyPermissionGroup = async ({
    permissionsGroupId,
  }: {
    permissionsGroupId: number;
  }) => {
    try {
      if (userId !== undefined && companyId !== undefined) {
        setIsFetching(true);

        await deleteUserCompanyPermissionGroupApi({
          userId,
          companyId,
          permissionGroupCompanyId: permissionsGroupId,
        });

        await loadPermissions();

        updateCompanyGroupsList &&
          (await updateCompanyGroupsList({ userId, update: true }));

        isUsersPage && redirectToCompanyGroupList();
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const redirectToCompanyGroupList = () => {
    if (companyPermissions) {
      const { companyPermissionGroups, companyPermissionsOverrides } =
        companyPermissions;
      const isCompanyPermissionsOverridesEmpty =
        Object.keys(companyPermissionsOverrides).length === 0;
      const isLastCompanyPermissionGroup = companyPermissionGroups.length === 1;

      if (isLastCompanyPermissionGroup && isCompanyPermissionsOverridesEmpty) {
        navigate(`/users/${userId}/details/company-security`);
      }
    } else {
      console.warn("companyPermissions is null");
    }
  };

  return {
    isInitialized,
    companyPermissionsData: companyPermissions,
    error,
    deleteCompanyPermissionGroup,
    isFetching,
    addGroupAndUpdateList,
    updatePermissionsList: loadPermissions,
    isUsersPage,
  };
};

export default useGetCompanyPermissions;
