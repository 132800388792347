import React from "react";
import { Route, Routes } from "react-router-dom";
import CompaniesPage from "../../pages/companies/CompaniesPage/CompaniesPage";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import { UserPermissions } from "../../store/slices/userDataSlice";
import CompanyDetailsPage from "../../pages/companies/CompanyDetailsPage/CompanyDetailsPage";
import CompanyUsersPage from "../../pages/companies/CompanyUsersPage/CompanyUsersPage";
import CustomerSitePage from "../../pages/companies/CustomerSitePage/CustomerSitePage";
import NotFoundPage from "../../pages/NotFoundPage";
import CompanyUserPage from '../../pages/companies/CompanyUserPage/CompanyUserPage';

const CompaniesRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_COMPANY_LIST}
            isAnywhereRole={true}
          >
            <CompaniesPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path=":companyId/details"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_COMPANY_READ}
            isCompanyRole={true}
          >
            <CompanyDetailsPage />
          </ProtectedByRoleRoute>
        }
      >
        <Route
          path="users"
          element={
            <ProtectedByRoleRoute
              permission={UserPermissions.ROLE_COMPANY_USERS_READ}
              isCompanyRole={true}
            >
              <CompanyUsersPage />
            </ProtectedByRoleRoute>
          }
        />

        <Route
          path="microsite"
          element={
            <ProtectedByRoleRoute
              permission={UserPermissions.ROLE_COMPANY_MICROSITE_READ}
              isCompanyRole={true}
            >
              <CustomerSitePage />
            </ProtectedByRoleRoute>
          }
        />
      </Route>

      <Route
        path=":companyId/user/:userId"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_USER_READ}
            isCompanyRole={true}
          >
            <CompanyUserPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default CompaniesRoutes;
