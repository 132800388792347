import React from 'react';
import LabelsHeader from '../../components/campaignLabels/LabelsHeader';
import LabelsList from '../../components/campaignLabels/LabelsList';

const CampaignLabelsPage = () => {
  return (
    <div className="flex flex-col gap-[24px]">
      <LabelsHeader />
      <LabelsList />
    </div>
  );
};

export default CampaignLabelsPage;