import {
  ClearOutlined,
  DeleteOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { Button, Input, Segmented, Table, Tag, Tooltip } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useConfirm from "../../../hooks/useConfirm";
import { getMessageApi } from "../../../store/slices/appSlice";
import { TCcVariableId } from "../../../store/slices/ccVariablesSlice";
import {
  bulkDeleteEnvVarThunk,
  getEnvVarTypes,
  TEnvVarData,
  TEnvVariable,
  TEnvVariableType,
} from "../../../store/slices/envVariablesSlice";
import { AppDispatch } from "../../../store/store";
import VariableItemSettings from "../VariableItemSettings/VariableItemSettings";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import VariableItemKey from "../VariableItemKey/VariableItemKey";

const { Column } = Table;

type Props = {
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setEditModeProps: React.Dispatch<React.SetStateAction<TEnvVariable | null>>;
  gridData: Array<TEnvVarData>;
};

const VariablesList: FC<Props> = ({
  setIsOpened,
  setEditModeProps,
  gridData,
}) => {
  const { companyId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const varTypes = getEnvVarTypes();
  const confirm = useConfirm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filteredData, setFilteredData] = useState<TEnvVarData[]>([]);
  const [filterByTypeValue, setFilterByTypeValue] = useState<
    TEnvVariableType | undefined
  >("prompt");
  const [searchText, setSearchText] = useState("");
  const messageApi = getMessageApi();
  const { hasCampaignEnvItemDeleteRole } = useUserHasPermission({ companyId });

  useEffect(() => {
    let filtered = gridData;

    if (filterByTypeValue) {
      filtered = gridData.filter((item) => item.type === filterByTypeValue);
    } else if (searchText) {
      filtered = gridData.filter((item) =>
        item.varKey.toLowerCase().includes(searchText),
      );
    }

    setFilteredData(filtered);
  }, [gridData]);

  const rowSelection: TableRowSelection<TEnvVarData> = {
    selectedRowKeys,
    columnWidth: 30,
    onChange: (keys) => {
      setSelectedRowKeys(keys);
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const onBulkDelete = async () => {
    try {
      const keysToDelete = selectedRowKeys.map((id) => {
        const parsedId: TCcVariableId = JSON.parse(id as string);
        return parsedId.key;
      });

      const res = await dispatch(bulkDeleteEnvVarThunk(keysToDelete));

      if ("error" in res) {
        messageApi.error(
          `An error occurred while trying to delete env variables: ${res.payload?.message}`,
        );
      } else {
        setSelectedRowKeys([]);
      }
    } catch (e: any) {
      messageApi.error(
        `An error occurred while trying to delete env variables: ${e?.message}`,
      );
      console.error(
        "An error occurred while trying to delete env variables:",
        e,
      );
    }
  };

  const onFilterTypeChange = (value: TEnvVariableType) => {
    const filtered = gridData.filter((item) => item.type === value);

    setFilteredData(filtered);
    setFilterByTypeValue(value);
    setSearchText("");
    setSelectedRowKeys([]);
  };

  const onClearFilter = () => {
    setFilterByTypeValue(undefined);
    setFilteredData(gridData);
    setSearchText("");
  };

  const onSearchKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();

    setSearchText(value);

    if (value.length === 0) {
      setFilteredData(gridData);
    } else {
      const filtered = gridData.filter((item) =>
        item.varKey.toLowerCase().includes(value),
      );

      setFilteredData(filtered);
      setSelectedRowKeys([]);
      setFilterByTypeValue(undefined);
    }
  };

  return (
    <div className="flex flex-col gap-2 h-full">
      <div className="flex items-center justify-between flex-wrap gap-y-2">
        <div className="flex gap-2 items-center flex-wrap">
          <Input.Search
            size="small"
            placeholder="Enter var key"
            onChange={onSearchKey}
            value={searchText}
            className="w-[200px]"
          />

          <div className="flex gap-2">
            <Segmented
              size="small"
              onChange={onFilterTypeChange}
              value={filterByTypeValue || undefined}
              options={varTypes}
            />
            <Button
              disabled={!filterByTypeValue && !searchText}
              size="small"
              type="default"
              icon={<ClearOutlined />}
              onClick={onClearFilter}
            >
              Clear filter
            </Button>

            <div className="flex-shrink-0">{`Items: ${filteredData.length}`}</div>
          </div>
        </div>

        {hasCampaignEnvItemDeleteRole && (
          <Button
            icon={<DeleteOutlined />}
            size="small"
            disabled={!selectedRowKeys.length}
            onClick={() =>
              confirm({ action: onBulkDelete, title: "Delete Variables" })
            }
            danger
          >
            Delete Selected
          </Button>
        )}
      </div>

      <Table
        size="small"
        dataSource={filteredData}
        bordered={true}
        rowSelection={hasCampaignEnvItemDeleteRole ? rowSelection : undefined}
        pagination={false}
        scroll={{ x: 500, y: 400 }}
        virtual
      >
        <Column
          title="Type"
          dataIndex="type"
          key="type"
          align="center"
          width={70}
          render={(type: TEnvVariableType) => {
            let color = "blue";
            switch (type) {
              case "string":
                color = "green";
                break;
              case "function":
                color = "blue";
                break;
              case "prompt":
                color = "red";
                break;
            }
            return <Tag color={color}>{type}</Tag>;
          }}
        />
        <Column
          title="Key"
          dataIndex="varKey"
          key="varKey"
          width={150}
          render={(key: string, { variable }: TEnvVarData) => (
            <VariableItemKey
              itemKey={key}
              modifiedTime={variable.modifiedTime}
              modifiedBy={variable.modifiedBy}
            />
          )}
        />
        <Column
          title="Value"
          dataIndex="value"
          key="value"
          render={(value: string) => {
            return <span className="whitespace-pre-wrap">{value}</span>;
          }}
        />
        <Column
          title=""
          key="action"
          width={70}
          render={(_: any, record: TEnvVarData) => {
            const { action } = record;
            const tooltipTitle =
              action && Object.keys(action).length
                ? JSON.stringify(action, null, "\t")
                : "No options in this variable";

            return (
              <div className="flex gap-4 mr-2">
                <Tooltip
                  title={tooltipTitle}
                  overlayStyle={{ maxWidth: "80vW" }}
                >
                  <InfoCircleTwoTone className="cursor-pointer" />
                </Tooltip>
                <VariableItemSettings
                  variable={record}
                  setIsOpened={setIsOpened}
                  setEditModeProps={setEditModeProps}
                />
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default VariablesList;
