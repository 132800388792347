import React from "react";

const customizeFormLabels = (
  label: React.ReactNode,
  { required }: { required: boolean },
) => (
  <span className="font-sans font-bold text-[#475569] text-[14px] flex">
    {label}
    {required && <span className="text-red-500 ml-1">*</span>}
  </span>
);

export default customizeFormLabels;
