import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteTemplateApi,
  updateTemplateIncludeCampaignStructureApi,
} from "src/api/document-templates.api";
import { ArrowRightIcon, DeleteIcon, Icons } from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import { TableCell, TableRow } from "src/components/common/ui/table";
import { docTemplateStateBadgeSettings } from "src/constants";
import { useDocTemplatesList } from "src/hooks/useDocTemplatesList";
import { selectMessageApi } from "src/store/slices/appSlice";
import { DocumentTemplateListItem } from "src/types/docTemplates";
import handleRequestError from "src/utils/handleRequestError";
import DeleteWithTitleConfirmationModal from "../common/modals/DeleteWithTitleConfirmationModal/DeleteWithTitleConfirmationModal";
import { EditTemplateClassesModal } from "../common/modals/EditTemplateClassesModal";
import { Badge } from "../common/ui/badge";
import { Spin } from "antd";
import { ViewKeysModal } from "../common/modals/ViewKeysModal";
import { TableCellsIcon } from "@heroicons/react/16/solid";
import { useUserHasPermission } from "../../hooks/useUserHasPermission";

type Props = {
  item: DocumentTemplateListItem;
};

const DocTemplateListItem: FC<Props> = ({ item }) => {
  const {
    id,
    name,
    state,
    mediaType,
    classes,
    keys,
    includeCampaignStructure,
  } = item;
  const [isEditClassesModalOpen, setIsEditClassesModalOpen] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const [isDeleteTemplateModalOpen, setDeleteTemplateModalOpen] =
    useState(false);
  const { updateClasses, deleteTemplate, updateTemplate } =
    useDocTemplatesList();
  const [isLoading, setIsLoading] = useState(false);
  const [displayKeysModal, setDisplayKeysModal] = useState(false);
  const navigate = useNavigate();
  const {
    hasDocumentTemplateReadRole,
    hasDocumentTemplateUpdateRole,
    hasDocumentTemplateDeleteRole,
    hasDictionarySearchRole,
  } = useUserHasPermission({});

  const navigateToEdit = () => {
    navigate(`/templates/documents/edit/${id}`);
  };

  const handleToggleIncludeCampaignStructure = async (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const toggledIncludeCampaignStructure = !includeCampaignStructure;

      await updateTemplateIncludeCampaignStructureApi({
        templateId: id,
        includeCampaignStructure: toggledIncludeCampaignStructure,
      });

      updateTemplate(id, {
        includeCampaignStructure: toggledIncludeCampaignStructure,
      });
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const menuItems = [
    hasDictionarySearchRole && {
      key: "editClasses",
      label: "Edit Classes",
      onClick: () => setIsEditClassesModalOpen(true),
      icon: ArrowRightIcon,
    },
    {
      key: "viewKeys",
      label: "View Keys",
      onClick: () => setDisplayKeysModal(true),
      icon: Icons.docs,
    },
    hasDocumentTemplateUpdateRole && {
      key: "edit",
      label: "Edit",
      onClick: navigateToEdit,
      icon: Icons.edit,
    },
    hasDocumentTemplateUpdateRole && {
      key: "includeCampaignStructure",
      label: item?.includeCampaignStructure
        ? "Campaign Structure Included"
        : "Campaign Structure Not Included",
      onClick: handleToggleIncludeCampaignStructure,
      icon: isLoading ? Spin : TableCellsIcon,
    },
    hasDocumentTemplateDeleteRole && {
      key: "delete",
      label: "Delete",
      onClick: () => setDeleteTemplateModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const handleDelete = async () => {
    try {
      await deleteTemplateApi({ templateId: id });

      deleteTemplate(id);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setDeleteTemplateModalOpen(false);
    }
  };

  const {
    label: badgeLabel = "",
    className: badgeClassName = "",
    icon: Icon,
  } = docTemplateStateBadgeSettings[state];

  return (
    <>
      <TableRow
        key={id}
        onClick={hasDocumentTemplateReadRole ? navigateToEdit : undefined}
        className="cursor-pointer relative h-[48px]"
      >
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {name}
          </span>
        </TableCell>
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {mediaType || "-"}
          </span>
        </TableCell>
        <TableCell>
          <Badge className={badgeClassName} variant={"state"}>
            {Icon && <Icon className="size-4 mr-[4px]" />}
            {badgeLabel}
          </Badge>
        </TableCell>
        {hasDictionarySearchRole && (
          <TableCell>
            <div className="flex flex-wrap gap-1">
              {classes?.length ? (
                classes.map((classItem) => (
                  <Badge
                    key={classItem}
                    className={"bg-transparent !text-slate-500 font-sans"}
                    variant={"tableOutline"}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditClassesModalOpen(true);
                    }}
                  >
                    {classItem}
                  </Badge>
                ))
              ) : (
                <Badge
                  className="bg-slate-100 text-slate-500 font-sans"
                  variant={"tableOutline"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditClassesModalOpen(true);
                  }}
                >
                  No assigned classes
                </Badge>
              )}
            </div>
          </TableCell>
        )}

        {menuItems.length ? (
          <TableCell onClick={(e) => e.stopPropagation()}>
            <MenuDropdown
              items={menuItems}
              dropdownClassName="w-[190px]"
              align="end"
            >
              <Button
                variant={"ghostPrimary"}
                className="size-[40px] p-2 flex justify-end"
              >
                <Icons.MoreDots className="size-[24px]" />
              </Button>
            </MenuDropdown>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
      {hasDictionarySearchRole && isEditClassesModalOpen && (
        <EditTemplateClassesModal
          open={isEditClassesModalOpen}
          setOpen={setIsEditClassesModalOpen}
          templateId={id}
          defaultClasses={classes}
          onUpdated={(classes) => {
            updateClasses(id, classes);
          }}
        />
      )}
      {hasDocumentTemplateDeleteRole && isDeleteTemplateModalOpen && (
        <DeleteWithTitleConfirmationModal
          entity="Document Template"
          title={name}
          onDelete={handleDelete}
          isModalOpen={isDeleteTemplateModalOpen}
          setIsModalOpen={setDeleteTemplateModalOpen}
        />
      )}
      {displayKeysModal && (
        <ViewKeysModal
          open={displayKeysModal}
          setOpen={setDisplayKeysModal}
          name={name}
          keys={keys}
        />
      )}
    </>
  );
};

export default DocTemplateListItem;
