import { Form, FormInstance, Select, Spin } from "antd";
import { FC } from "react";
import { TDynamicSelectProps } from "../../../globalTypes";
import { camelCaseToWords } from "../../../utils/cm.utils";
import useGetOptionsHook from "./useGetOptions.hook";
import { Link } from "react-router-dom";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";

type Props = {
  selectProps: TDynamicSelectProps;
  hidden: boolean;
  form: FormInstance<any>;
  restValuesProvider: string;
  campaignId: number;
  stepId: number;
  phaseId: number;
};

const SelectWithOptionsProvider: FC<Props> = ({
  selectProps,
  hidden,
  restValuesProvider,
  form,
  stepId,
  phaseId,
  campaignId,
}) => {
  const { required, dependsOn, name } = selectProps;
  const validatedName = camelCaseToWords(name);
  const { isFetching, selectOptions } = useGetOptionsHook({
    stepId,
    campaignId,
    restValuesProvider,
    dependsOn,
    form,
    name,
    phaseId,
  });

  const { hasDocumentTemplateListRole } = useUserHasPermission({});

  const isEmptyTemplates =
    name === "documentTemplateId" && selectOptions?.length === 0;
  const emptyTemplatesErrorMsg = (
    <span>
      There are no templates for this class. Please ask an admin to add a class
      template from the template library{" "}
      {hasDocumentTemplateListRole ? (
        <Link to="/templates/documents">Velocity Engine</Link>
      ) : (
        <span>Velocity Engine</span>
      )}
    </span>
  );

  if (isEmptyTemplates) {
    form.validateFields([name]);
  }

  return (
    <Spin spinning={isFetching}>
      <Form.Item
        key={name}
        hidden={hidden}
        name={name}
        label={validatedName}
        rules={[
          {
            required: required,
            message: isEmptyTemplates
              ? emptyTemplatesErrorMsg
              : "Required field!",
          },
        ]}
      >
        <Select
          placeholder={
            isEmptyTemplates
              ? "No templates available"
              : `Choose ${validatedName}`
          }
          options={selectOptions || []}
          disabled={isEmptyTemplates}
        />
      </Form.Item>
    </Spin>
  );
};

export default SelectWithOptionsProvider;
