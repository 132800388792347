import React from "react";
import { Route, Routes } from "react-router-dom";
import { UserPermissions } from "../../store/slices/userDataSlice";
import CampaignTemplatesPage from "../../pages/campaign-templates/CampaignsPage";
import GenerateTemplatePage from "../../pages/generateTemplate/GenerateTemplatePage";
import TemplateDataPage from "../../pages/templateData/TemplateDataPage";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import DocumentTemplatesPage from "../../pages/docTemplates/DocTemplatesPage";
import NotFoundPage from "../../pages/NotFoundPage";

const TemplatesRoutes = () => {
  return (
    <Routes>
      <Route
        path="campaigns"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_CAMPAIGN_TEMPLATES_LIST}
            isAnywhereRole={true}
          >
            <CampaignTemplatesPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path="documents"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_DOCUMENT_TEMPLATE_LIST}
          >
            <DocumentTemplatesPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path="documents/generate-figma"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_DOCUMENT_TEMPLATE_HTML_2_XSL}
          >
            <GenerateTemplatePage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path="documents/create"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_DOCUMENT_TEMPLATE_CREATE}
          >
            <TemplateDataPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path="documents/edit/:templateId"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_DOCUMENT_TEMPLATE_READ}
          >
            <TemplateDataPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default TemplatesRoutes;
