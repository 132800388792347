import { Form, Modal, Select } from "antd";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setCurrentCampaignView } from "src/store/slices/campaignsSlice";
import { updateCurrentStepTemplateThunk } from "src/store/slices/stepsSlice";
import { CampaignViewTypes } from "src/types";
import useSubmitFormOnEnter from "../../hooks/useSubmitFormOnEnter";
import submitFormWithTrim from "../../utils/submitFormWithTrim";
import { useUserHasPermission } from "../../hooks/useUserHasPermission";
import { ITemplateOption } from "../../globalTypes";
import { AppDispatch } from "../../store/store";

export type TTemplatePreviewFormValues = {
  templateId: number | null;
};

export type TTemplatePreviewModalProps = {
  onCancel: () => void;
  stepId: number;
  phaseId: number;
  options: ITemplateOption[];
  companyId: number;
};

const TemplatePreviewModal: FC<TTemplatePreviewModalProps> = ({
  onCancel,
  options,
}) => {
  const [form] = Form.useForm<TTemplatePreviewFormValues>();
  const dispatch: AppDispatch = useDispatch();
  const isEmpty = options?.length === 0;
  const templateId = "templateId";
  const { hasDocumentTemplateListRole } = useUserHasPermission({});

  useEffect(() => {
    if (isEmpty) {
      form.validateFields([templateId]);
    }
  }, [isEmpty]);

  useSubmitFormOnEnter(() => submitForm());

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async ({
      templateId,
    }: TTemplatePreviewFormValues) => {
      if (templateId !== null) {
        dispatch(setCurrentCampaignView(CampaignViewTypes.DOC));
        dispatch(updateCurrentStepTemplateThunk({ templateId }));
      } else {
        console.warn("No [templateId]");
      }

      onCancel();
    },
  });

  const customValidationMessage = (
    <div className="text-red-500 text-sm mt-1 mb-4">
      There are no templates for this class. Please ask an admin to add a class
      template from the template library{" "}
      {hasDocumentTemplateListRole ? (
        <Link to="/templates/documents">Velocity Engine</Link>
      ) : (
        <span>Velocity Engine</span>
      )}
    </div>
  );

  return (
    <Modal
      title="Step Data Preview"
      open={true}
      onOk={submitForm}
      okText="Preview"
      width={500}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" name="step_template_preview">
        <Form.Item
          name={templateId}
          label="Select Template"
          rules={[
            {
              required: true,
              message: isEmpty ? customValidationMessage : "Required field!",
            },
          ]}
        >
          <Select
            placeholder={isEmpty ? "No templates available" : `Choose template`}
            options={options}
            disabled={isEmpty}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TemplatePreviewModal;
