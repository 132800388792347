import { Checkbox, Form, FormInstance, Input, Select } from "antd";
import React from "react";
import { executeFunctionVarApi } from "../../../api/env-variables.api";
import {
  TDynamicItem,
  TDynamicSelectProps,
  TDynamicTextareaProps,
} from "../../../globalTypes";
import { getCurrentCampaign } from "../../../store/slices/campaignsSlice";
import { TEnvVarsFormValues } from "../../../store/slices/envVariablesSlice";
import { getCurrentStep } from "../../../store/slices/stepsSlice";
import { camelCaseToWords } from "../../../utils/cm.utils";
import TextAreaWithAltEnter from "../../common/TextAreaWithAltEnter";
import ExecuteScriptContent from "../../dynamicFormItems/ExecuteScriptContent/ExecuteScriptContent";
import SelectWithOptionsProvider from "../../dynamicFormItems/SelectWithDependsOn/SelectWithOptionsProvider";
import { getCurrentPhase } from "../../../store/slices/phasesSlice";

type PropsType = {
  isTypeSelected: boolean;
  form: FormInstance<TEnvVarsFormValues>;
  items: TDynamicItem[];
};

const DynamicForms = (props: PropsType) => {
  const { isTypeSelected, form, items } = props;
  const currentCampaign = getCurrentCampaign();
  const currentStep = getCurrentStep();
  const currentPhase = getCurrentPhase();

  if (!items.length) return <></>;

  const executeScriptContent = async (scriptContent: string) => {
    const { data } = await executeFunctionVarApi({
      campaignId: currentCampaign!.id,
      scriptContent,
    });

    form.setFieldsValue({
      value: data,
    });
  };

  return (
    <>
      {items.map((item) => {
        const { type, name, required } = item;
        const validatedLabel = camelCaseToWords(name);
        let formItem: React.ReactElement = <></>;

        switch (type) {
          case "checkbox":
            formItem = (
              <div key={name}>
                <Form.Item
                  hidden={!isTypeSelected}
                  valuePropName="checked"
                  name={name}
                  noStyle
                >
                  <Checkbox className="font-sans font-bold text-[#475569] text-[14px] mb-[24px]">
                    {validatedLabel}
                  </Checkbox>
                </Form.Item>
              </div>
            );
            break;
          case "textarea":
            {
              const textareaProps = item as TDynamicTextareaProps;
              const { properties } = textareaProps;
              const rows = +properties.rows || 5;

              if (name === "scriptContent") {
                formItem = (
                  <ExecuteScriptContent
                    key={name}
                    rows={rows}
                    label={validatedLabel}
                    hidden={!isTypeSelected}
                    required={required}
                    executeScriptContent={executeScriptContent}
                    name={name}
                    form={form}
                  />
                );
              } else {
                formItem = (
                  <Form.Item
                    key={name}
                    hidden={!isTypeSelected}
                    name={name}
                    label={validatedLabel}
                    rules={[
                      {
                        required: required,
                        message: "Required field!",
                      },
                    ]}
                  >
                    <TextAreaWithAltEnter
                      placeholder={`Enter ${validatedLabel}`}
                      currentForm={form}
                      fieldName={name}
                      rows={rows}
                    />
                  </Form.Item>
                );
              }
            }
            break;
          case "text_input":
            formItem = (
              <Form.Item
                key={name}
                hidden={!isTypeSelected}
                name={name}
                label={validatedLabel}
                rules={[
                  {
                    required: required,
                    message: "Required field!",
                  },
                ]}
              >
                <Input placeholder={`Enter ${validatedLabel}`} />
              </Form.Item>
            );
            break;
          case "select":
            {
              const selectProps = item as TDynamicSelectProps;
              const { values, properties } = selectProps;
              const restValuesProvider = properties.restValuesProvider;

              if (restValuesProvider) {
                formItem = (
                  <SelectWithOptionsProvider
                    key={name}
                    selectProps={selectProps}
                    hidden={!isTypeSelected}
                    form={form}
                    restValuesProvider={restValuesProvider}
                    campaignId={currentCampaign!.id}
                    stepId={currentStep?.id!}
                    phaseId={currentPhase?.id!}
                  />
                );
              } else {
                formItem = (
                  <Form.Item
                    key={name}
                    hidden={!isTypeSelected}
                    name={name}
                    label={validatedLabel}
                    rules={[
                      {
                        required,
                        message: "Required field!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={`Choose ${validatedLabel}`}
                      options={values.map((value) => ({
                        value,
                        label: value,
                      }))}
                    />
                  </Form.Item>
                );
              }
            }
            break;
        }
        return formItem;
      })}
    </>
  );
};

export default DynamicForms;
