import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import { getModalApi } from "../store/slices/appSlice";

const useConfirm = () => {
  const modalApi = getModalApi();

  return ({
    action,
    title,
    content,
    cancelAction,
    okText,
    cancelText,
  }: {
    action: () => void;
    title: string;
    content?: React.ReactNode;
    cancelAction?: () => void;
    okText?: string;
    cancelText?: string;
  }) => {
    const isDanger = title.toLowerCase().includes("delete");

    modalApi.confirm({
      title,
      icon: (
        <QuestionCircleOutlined
          style={{ color: isDanger ? "red" : "#1677ff" }}
        />
      ),
      onOk: action,
      content: (
        <>
          {content ? content : <span>Are you sure you want to continue?</span>}
        </>
      ),
      okButtonProps: { danger: isDanger },
      okText: okText || "Continue",
      cancelText: cancelText || "No",
      onCancel: cancelAction,
    });
  };
};

export default useConfirm;
