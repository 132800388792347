import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  loadCampaignDataThunk,
  selectCurrentCampaign,
  selectCurrentCampaignView,
  setCurrentCampaign,
  updateCampaignView,
} from "../../../store/slices/campaignsSlice";
import {
  connectSocketThunk,
  disconnectSocketThunk,
  setSocketShouldReconnect,
} from "../../../store/slices/computationMessagesSlice";
import {
  selectCurrentPhase,
  selectPhasesList,
} from "../../../store/slices/phasesSlice";
import { AppDispatch } from "../../../store/store";
import { CampaignViewTypes } from "../../../types";

const useInitCampaignHook = () => {
  const { companyId, campaignId, phaseId, stepId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const navigate = useNavigate();
  const currentCampaign = useSelector(selectCurrentCampaign);
  const currentPhase = useSelector(selectCurrentPhase);
  const phases = useSelector(selectPhasesList);
  const view = useSelector(selectCurrentCampaignView);
  const [isInitialized, setIsInitialized] = useState(false);

  const handleViewChange = (newView: CampaignViewTypes) => {
    dispatch(updateCampaignView({ viewType: newView }));
    setSearchParams({ view: newView }, { replace: true });
  };

  useEffect(() => {
    const viewFromParams = searchParams.get("view");
    if (stepId && !viewFromParams) {
      const currentHash = window.location.hash;

      setSearchParams({ view }, { replace: true });

      window.location.hash = currentHash;
    }
  }, [view, stepId]);

  useEffect(() => {
    const getData = async ({
      companyId,
      campaignId,
    }: {
      companyId: number;
      campaignId: number;
    }) => {
      try {
        const navigatePath = await dispatch(
          loadCampaignDataThunk({
            campaignId,
            companyId,
            phaseId: phaseId ? +phaseId : undefined,
            stepId: stepId ? +stepId : undefined,
          }),
        ).unwrap();

        dispatch(setSocketShouldReconnect(true));
        dispatch(connectSocketThunk({ campaignId }));

        // Get view from query params or set default
        let viewFromParams = searchParams.get(
          "view",
        ) as CampaignViewTypes | null;

        if (
          !viewFromParams ||
          !Object.values(CampaignViewTypes).includes(viewFromParams)
        ) {
          viewFromParams = CampaignViewTypes.GRID; // Set default view
        }

        dispatch(updateCampaignView({ viewType: viewFromParams }));

        if (navigatePath) {
          navigate(
            `${navigatePath}?view=${viewFromParams}${window.location.hash}`,
            { replace: true },
          );
        } else {
          setSearchParams({ view: viewFromParams }, { replace: true });
        }
      } catch (e: any) {
        messageApi.error(e?.message);
        console.error("get all data ERR:", e);

        navigate(`/campaigns`);
      } finally {
        setIsInitialized(true);
      }
    };

    if (companyId && campaignId) {
      getData({ companyId: +companyId, campaignId: +campaignId });
    }

    return () => {
      dispatch(disconnectSocketThunk());
      dispatch(setCurrentCampaign(null));
    };
  }, [campaignId]);

  return {
    currentCampaign,
    currentPhase,
    phases,
    companyId,
    isInitialized,
    view,
    handleViewChange,
  };
};

export default useInitCampaignHook;
