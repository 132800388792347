import { Form, Input, InputRef, Modal } from "antd";
import React, { useRef, useState } from "react";
import {
  createAssetApi,
  unpublishAssetApi,
  updateAssetApi,
} from "../../../api/assets.api";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../constants";
import micrositeContextFolderTooltip from "../../../constants/micrositeContextFolderTooltip";
import { TAsset } from "../../../globalTypes";
import useSetFocus from "../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { getMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import {
  minMax,
  required,
  validateMicrositeAssetPath,
} from "../../../utils/validations";
import SelectAssetFromPublicCompanyStorage, {
  SelectAssetType,
} from "../../common/SelectAssetFromPublicCompanyStorage";
import SelectMicrositeStorageFolder, {
  SelectMicrositeFolderType,
} from "../../common/SelectMicrositeStorageFolder";
import { useParams } from "react-router-dom";
import { getConfirmationMessage } from "../../../utils/getConfirmationMessage";
import customizeFormLabels from "../../../utils/customizeFormLabels";

type TAssetFormValues = {
  title: string;
  ref: string;
  microSiteTargetFolder: string;
};

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  campaignId: string;
  stepId: string;
  editAsset: TAsset | null;
  setAssets?: React.Dispatch<React.SetStateAction<TAsset[]>>;
  onAssetCreated?: (asset: TAsset) => void;
  onAssetUpdated?: (asset: TAsset) => void;
};
const AssetModal: React.FC<Props> = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    campaignId,
    stepId,
    editAsset,
    setAssets,
    onAssetUpdated,
    onAssetCreated,
  } = props;
  const { companyId } = useParams();
  const inputRef = useRef<InputRef>(null);
  const messageApi = getMessageApi();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<TAssetFormValues>();
  const initialFormValues: TAssetFormValues = {
    title: editAsset?.title || "",
    microSiteTargetFolder: editAsset?.microSiteTargetFolder || "",
    ref: editAsset?.ref || "",
  };
  const formValues = Form.useWatch([], form);
  const isFormChanged =
    JSON.stringify(formValues) !== JSON.stringify(initialFormValues);

  useSetFocus(inputRef);
  useSubmitFormOnEnter(() => onFormSubmit(), {
    condition: !isLoading && isFormChanged,
  });

  const onCreateAsset = async (values: TAssetFormValues) => {
    try {
      setIsLoading(true);
      const { data } = await createAssetApi({
        ...values,
        type: "LINKED",
        campaignId,
        stepId,
      });

      setAssets?.((assets) => [...assets, data]);
      onAssetCreated?.(data);
      setIsModalOpen(false);
      form.resetFields();

      messageApi.success("Asset has been successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateAsset = async (values: TAssetFormValues) => {
    if (editAsset) {
      try {
        setIsLoading(true);

        const isFolderContextChanged =
          values.microSiteTargetFolder !== editAsset.microSiteTargetFolder;
        const isAssetTitleChanged = values.title !== editAsset.title;
        const isAssetPublished =
          editAsset.approved &&
          editAsset.publishToMicroSite &&
          editAsset.publishedDate !== null;

        const updateAsset = async (unpublish?: boolean) => {
          try {
            if (unpublish) {
              await unpublishAssetApi({
                campaignId,
                assetId: editAsset.id,
              });
            }
            const updatedAsset = {
              ...editAsset,
              ...values,
            };

            const { data } = await updateAssetApi({
              ...updatedAsset,
            });

            setAssets?.((assets) =>
              assets.map((asset) =>
                asset.id === updatedAsset.id ? data : asset,
              ),
            );

            onAssetUpdated?.(data);
            setIsModalOpen(false);
            form.resetFields();
            messageApi.success("Asset has been successfully updated");
          } catch (e: any) {
            const customError = handleRequestError(e);
            messageApi.error(customError.message);
            console.error(customError);
          } finally {
            setIsLoading(false);
          }
        };

        const confirmationMessage = getConfirmationMessage(
          isFolderContextChanged,
          isAssetTitleChanged,
          "Asset Title",
        );
        if (confirmationMessage && isAssetPublished) {
          Modal.confirm({
            title: "Confirm asset change",
            content: `${confirmationMessage} The published asset will be deleted.`,
            okText: "Yes",
            cancelText: "Cancel",
            onOk: () => updateAsset(true),
          });
        } else {
          await updateAsset();
        }
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const insertFileData: SelectAssetType = ({
    filePath,
    filePublicUrl,
    fileName,
  }) => {
    form.setFieldsValue({
      ref: filePublicUrl,
      microSiteTargetFolder: filePath,
      title: fileName,
    });
  };

  const insertFolderData: SelectMicrositeFolderType = (path: string) => {
    form.setFieldsValue({
      microSiteTargetFolder: path,
    });
  };

  const onFormSubmit = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TAssetFormValues) => {
      editAsset ? await onUpdateAsset(values) : await onCreateAsset(values);
    },
  });

  return (
    <Modal
      title={`${editAsset ? "Edit" : "Add"} Asset`}
      open={isModalOpen}
      onOk={onFormSubmit}
      okText={editAsset ? "Update" : "Save"}
      width={600}
      destroyOnClose
      okButtonProps={{ loading: isLoading, disabled: !isFormChanged }}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={customizeFormLabels}
        name="asset_form"
        initialValues={initialFormValues}
      >
        <Form.Item
          name="title"
          label="Asset Title"
          tooltip="Please enter a string up to 255 characters long."
          rules={[required(), minMax({ max: 255, text: "Asset Title" })]}
        >
          <Input
            placeholder="Enter asset title"
            ref={inputRef}
            disabled={!!editAsset}
          />
        </Form.Item>
        {editAsset && (
          <Form.Item
            label="Microsite Context Folder"
            tooltip={micrositeContextFolderTooltip}
          >
            <Form.Item
              name="microSiteTargetFolder"
              rules={[
                minMax({ max: 255, text: "Path" }),
                validateMicrositeAssetPath,
              ]}
              noStyle
            >
              <Input placeholder={MICROSITE_INPUT_PLACEHOLDER} />
            </Form.Item>
            {companyId && (
              <SelectMicrositeStorageFolder
                companyId={companyId}
                onSelect={insertFolderData}
              />
            )}
          </Form.Item>
        )}

        <Form.Item label="Asset Link" required>
          <Form.Item
            name="ref"
            noStyle
            rules={[
              {
                type: "string",
                required: true,
                whitespace: true,
                message: "Required field!",
              },
            ]}
          >
            <Input placeholder="Enter asset link" disabled={!!editAsset} />
          </Form.Item>

          {!editAsset && (
            <SelectAssetFromPublicCompanyStorage onSelect={insertFileData} />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssetModal;
