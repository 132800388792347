import { FormInstance } from "antd";
import clsx from "clsx";
import React, { FC, useState } from "react";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { computationApi } from "src/api/computation.api";
import DnDMenuDropdown from "src/components/common/DnDMenuDropdown";
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  CloneIcon,
  DeleteIcon,
  ExecuteIcon,
  ExportDocIcon,
  GlobeIcon,
  Icons,
  InsertAfterIcon,
  InsertBeforeIcon,
} from "src/components/common/Icons";
import { MenuDropdownItem } from "src/components/common/MenuDropdown";
import { ComputationType } from "src/constants";
import {
  copyPhaseToApi,
  exportCSVPhaseApi,
  publishPhaseToMicroSiteApi,
} from "../../../api/phases.api";
import { TPhase, TPhaseFormValues } from "../../../globalTypes";
import useConfirm from "../../../hooks/useConfirm";
import useModals from "../../../hooks/useModals";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  clonePhaseThunk,
  deletePhaseThunk,
  selectPhaseThunk,
} from "../../../store/slices/phasesSlice";
import { AppDispatch } from "../../../store/store";
import { downloadTextFile } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import CloneEntityModal, {
  TCloneEntityFormValues,
} from "../../common/modals/CloneEntityModal/CloneEntityModal";
import DeleteWithFilesModal from "../../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import { TCloneToPhaseFormValues } from "../CloneToPhaseModal/CloneToPhaseModal";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import { resetComputationMessages } from "../../../store/slices/computationMessagesSlice";

type Props = {
  campaignId: number;
  campaignName: string;
  companyId: number;
  phase: TPhase;
  isCurrentPhase: boolean;
  form: FormInstance<TPhaseFormValues>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditPhase: React.Dispatch<React.SetStateAction<TPhase | null>>;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  onAddBefore?: () => void;
  onAddAfter?: () => void;
  onMoveBefore?: () => Promise<void>;
  onMoveAfter?: () => Promise<void>;
};

const Phase: FC<Props> = ({
  campaignId,
  phase,
  form,
  setIsModalOpen,
  setEditPhase,
  campaignName,
  isCurrentPhase,
  companyId,
  dragHandleProps,
  onAddBefore,
  onAddAfter,
  onMoveAfter,
  onMoveBefore,
}) => {
  const { cloneToPhase, execute, modal } = useModals();
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = getMessageApi();
  const confirm = useConfirm();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const navigate = useNavigate();

  const {
    hasPhaseDeleteRole,
    hasPhaseCloneRole,
    hasPhaseUpdateRole,
    hasPhaseDeployRole,
    hasCampaignPhaseComputationRole,
    hasPhaseMoveRole,
    hasPhaseExportRole,
    hasPhaseCreateRole,
    hasPhaseCopyRole,
    hasStepShowDocViewRole,
  } = useUserHasPermission({ companyId });

  const onDelete = async (removeFiles: boolean) => {
    try {
      const { phaseId, stepId } = await dispatch(
        deletePhaseThunk({
          campaignId,
          phaseId: phase.id,
          removeFiles,
        }),
      ).unwrap();

      let path = `/campaigns/company/${companyId}/campaign/${campaignId}`;

      if (phaseId !== undefined) {
        path += `/phase/${phaseId}`;

        if (stepId !== undefined) {
          path += `/step/${stepId}`;
        }
      }

      navigate(path);
      messageApi.success("The phase has been successfully removed");
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const onCopyTo = async (values: TCloneToPhaseFormValues) => {
    try {
      const {
        companyId: targetCompanyId,
        campaignId: targetCampaignId,
        targetPhaseSeq,
        name,
        resetResult,
        resetOvr,
      } = values;

      const { data } = await copyPhaseToApi({
        phaseId: phase.id,
        newSeq: targetPhaseSeq,
        targetCampaignId,
        campaignId,
        name,
        resetOvr,
        resetResult,
      });

      navigate(
        `/campaigns/company/${targetCompanyId}/campaign/${targetCampaignId}/phase/${data.id}`,
        { replace: true },
      );
    } catch (e: any) {
      let error = e;

      if (e?.response?.data instanceof Blob) {
        try {
          error = JSON.parse(await e.response.data.text());
        } catch (e) {
          console.error("Can't get and parse error response from Blob:", e);
        }
      }

      const customError = handleRequestError(error);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onSelectPhase = async () => {
    try {
      const { phaseId, stepId } = await dispatch(
        selectPhaseThunk({ phase, hasStepShowDocViewRole }),
      ).unwrap();

      let path = `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}`;

      if (stepId !== undefined) {
        path += `/step/${stepId}`;
      }

      navigate(path, { replace: true });
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const onEdit = () => {
    form.setFieldsValue({
      name: phase.name,
      hidden: phase.hidden,
      microSiteContextFolder: phase.microSiteContextFolder,
    });

    setEditPhase(phase);
    setIsModalOpen(true);
  };

  const onExportCSV = async () => {
    try {
      const { data } = await exportCSVPhaseApi({
        campaignId,
        phaseId: phase.id,
      });

      downloadTextFile({
        data,
        fileName: `${campaignName}_${phase.name}.csv`,
        type: "text/csv",
      });

      messageApi.success("The CSV file has been successfully downloaded");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const handleRequestExecute = async (
    computationType: ComputationType,
    republish: boolean,
    updateDownstreamDependencies: boolean,
  ) => {
    try {
      const { data: isExecuting } = await computationApi({
        campaignId,
      }).validatePhase({ phaseId: phase.id });

      if (!isExecuting) {
        await computationApi({ campaignId }).executePhase({
          phaseId: phase.id,
          computationType,
          republish,
          updateDownstreamDependencies,
        });

        dispatch(resetComputationMessages());

        messageApi.success("Execution started successfully");
      } else {
        messageApi.error(
          "There are some active computation elements in progress, please wait before execute",
        );
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onClone = async (values: TCloneEntityFormValues) => {
    try {
      await dispatch(clonePhaseThunk({ phase, params: values })).unwrap();

      setIsCloneModalOpen(false);
      messageApi.success("The phase has been successfully cloned");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const publishToMicroSite = async () => {
    try {
      await publishPhaseToMicroSiteApi({
        phaseId: phase.id,
        campaignId,
      });

      messageApi.success(
        "The phase has been successfully published to micro site",
      );
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const items = [
    hasCampaignPhaseComputationRole && {
      label: "Execute",
      key: "1",
      icon: ExecuteIcon,
      onClick: () =>
        execute({
          title: "Execute Phase",
          executionType: "EXECUTION_PHASE",
          requestExecute: handleRequestExecute,
        }),
      className: "!text-primary",
    },
    hasPhaseCloneRole && {
      label: "Clone",
      key: "7",
      icon: CloneIcon,
      onClick: () => setIsCloneModalOpen(true),
    },
    hasPhaseUpdateRole && {
      label: "Edit",
      key: "9",
      icon: Icons.edit,
      onClick: onEdit,
    },
    hasPhaseDeleteRole && {
      label: "Delete",
      key: "12",
      icon: DeleteIcon,
      onClick: () => setIsDeleteModalOpen(true),
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const moreItems = [
    hasPhaseExportRole && {
      label: "Export CSV",
      key: "2",
      icon: ExportDocIcon,
      onClick: () =>
        confirm({
          action: onExportCSV,
          title: "Export as CSV",
        }),
    },
    hasPhaseMoveRole && {
      label: "Move Left",
      key: "3",
      icon: InsertBeforeIcon,
      disabled: !onMoveBefore,
      onClick: onMoveBefore,
    },
    hasPhaseMoveRole && {
      label: "Move Right",
      key: "4",
      icon: InsertAfterIcon,
      disabled: !onMoveAfter,
      onClick: onMoveAfter,
    },
    hasPhaseCreateRole && {
      label: "Add Before",
      key: "5",
      icon: ArrowUturnLeftIcon,
      onClick: onAddBefore,
    },
    hasPhaseCreateRole && {
      label: "Add After",
      key: "6",
      icon: ArrowUturnRightIcon,
      onClick: onAddAfter,
    },
    hasPhaseCopyRole && {
      label: "Copy To",
      key: "8",
      icon: CloneIcon,
      onClick: () =>
        cloneToPhase({
          entityTitle: phase.name,
          onSubmitForm: onCopyTo,
        }),
    },
    hasPhaseDeployRole && {
      label: "Micro Site Publish",
      key: "10",
      onClick: () =>
        confirm({
          action: publishToMicroSite,
          title: "Publish to Micro Site",
        }),
      icon: GlobeIcon,
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const isMenuListNotEmpty = !!items.length || !!moreItems.length;

  return (
    <>
      {modal}
      {isCurrentPhase ? (
        <>
          <div className="relative">
            <DnDMenuDropdown items={items} moreItems={moreItems}>
              {(ref, setOpen, DropdownMenuTrigger) => (
                <>
                  {isMenuListNotEmpty && (
                    <div
                      className="absolute right-[10px] flex items-center justify-center rounded-full cursor-pointer hover:bg-primary/10 transition-colors duration-200 size-6 top-3"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(true);
                      }}
                    >
                      <Icons.MoreDots className="size-5" />
                    </div>
                  )}

                  <div
                    className={clsx(
                      "h-12 pr-10 bg-indigo-50 border-t-[3px] border-primary justify-between items-center gap-2 inline-flex cursor-pointer flex-col",
                      {
                        "hover:bg-indigo-100": isCurrentPhase,
                      },
                    )}
                    {...dragHandleProps}
                  >
                    <div className="text-slate-700 pl-4 h-full text-sm !font-semibold !font-sans leading-[14px] line-clamp-1 whitespace-nowrap inline-flex flex-col justify-center pt-[6px]">
                      {phase.name}
                    </div>
                    <DropdownMenuTrigger asChild ref={ref}>
                      <div className="h-0 w-full" />
                    </DropdownMenuTrigger>
                  </div>
                </>
              )}
            </DnDMenuDropdown>
          </div>

          {isCloneModalOpen && (
            <CloneEntityModal
              entity="Phase"
              entityTitle={phase.name}
              entityTitleTooltip="Please enter a string between 4 and 26 characters."
              onSubmitForm={onClone}
              isModalOpen={isCloneModalOpen}
              onCancel={() => setIsCloneModalOpen(false)}
            />
          )}
          {isDeleteModalOpen && (
            <DeleteWithFilesModal
              isModalOpen={isDeleteModalOpen}
              entity="Phase"
              title={phase.name}
              setIsModalOpen={setIsDeleteModalOpen}
              onDelete={onDelete}
            />
          )}
        </>
      ) : (
        <div
          className="h-12 px-4 py-[19px] bg-slate-50 border-r border-slate-200 justify-start items-center gap-2 inline-flex cursor-pointer border-y-[3px] border-y-transparent transition-colors hover:border-t-primary hover:bg-primary/10 tab line-clamp-1 whitespace-nowrap font-semibold font-sans text-sm text-slate-700"
          onClick={onSelectPhase}
          {...dragHandleProps}
        >
          {phase.name}
          <div>
            {isMenuListNotEmpty && (
              <Icons.MoreDots className="size-5 ml-2 tab-icon" />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Phase;
