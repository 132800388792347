import { Modal } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  editCampaignThunk,
  TCampaign,
} from "../../../store/slices/campaignsSlice";
import { AppDispatch } from "../../../store/store";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";

type PropsType = {
  currentCampaign: TCampaign;
  companyId: number;
};

const EditCampaignName: FC<PropsType> = (props) => {
  const { currentCampaign, companyId } = props;
  const campaignName = currentCampaign.name;
  const messageApi = getMessageApi();
  const dispatch: AppDispatch = useDispatch();
  const [editedCampaignName, setEditedCampaignName] = useState(campaignName);
  const [validationErr, setValidationErr] = useState("");
  const { hasCampaignUpdateRole } = useUserHasPermission({ companyId });

  useEffect(() => {
    setEditedCampaignName(currentCampaign.name);
  }, [currentCampaign]);

  const onNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const trimmed = newName ? newName.trim() : "";
    let err = "";

    if (trimmed.length < 4) {
      err = "The name must not be less than 4 characters!";
    }

    if (trimmed.length > 255) {
      err = "The name must contain no more than 255 characters!";
    }

    if (/[\\/]/.test(trimmed)) {
      err = "The name must not contain slashes!";
    }

    setValidationErr(err);
    setEditedCampaignName(newName);
  };

  const resetEditingState = () => {
    setEditedCampaignName(campaignName);
    setValidationErr("");
  };

  const saveName = async () => {
    try {
      const newCampaignData: TCampaign = {
        ...currentCampaign,
        name: editedCampaignName.trim().replace(/ +/g, " "),
      };

      await dispatch(
        editCampaignThunk({
          campaign: newCampaignData,
          rebuild: true,
        }),
      ).unwrap();

      messageApi.success("The campaign has been successfully renamed");
    } catch (e: any) {
      messageApi.error(e?.message);
      resetEditingState();
      console.error(
        "An error occurred while trying to rename the campaign:",
        e,
      );
    }
  };

  const onSaveName = async () => {
    if (validationErr) {
      setEditedCampaignName(campaignName);
      resetEditingState();
      return;
    }

    if (editedCampaignName !== campaignName) {
      Modal.confirm({
        title: "Confirm campaign name change",
        content:
          "Campaign name has changed, do you want to republish resources?",
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: saveName,
        onCancel: resetEditingState,
      });
    } else {
      resetEditingState();
    }
  };

  return (
    <div className="w-full relative">
      {!!validationErr && (
        <span className="absolute left-0 top-[-20px] text-[10px] text-red-600">
          {`${validationErr} The result will not be saved.`}
        </span>
      )}

      <input
        className="w-full min-w-[100px] px-[8px] py-[4px] text-slate-900 text-xl font-semibold font-sans leading-tight rounded-lg border border-transparent hover:border-gray-500 transition-all"
        value={editedCampaignName}
        type="text"
        autoComplete="off"
        readOnly={!hasCampaignUpdateRole}
        style={{ pointerEvents: hasCampaignUpdateRole ? "auto" : "none" }}
        tabIndex={hasCampaignUpdateRole ? 0 : -1}
        onChange={onNameInputChange}
        onBlur={onSaveName}
      />
    </div>
  );
};

export default EditCampaignName;
