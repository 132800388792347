import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import packageJson from "../../../package.json";
import logo from "../../assets/images/logo_horizontal.svg";
import UserAvatar from "../common/Avatar";
import VersionButton from "../common/VersionButton";
import ReleasesPopup from "./ReleasesPopup";
import { useSelector } from "react-redux";
import {
  selectUserData,
  UserPermissions,
} from "../../store/slices/userDataSlice";
import { useUserHasPermission } from "../../hooks/useUserHasPermission";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import clsx from "clsx";
import "./Header.scss";

type HeaderNavItem = {
  label: string;
  active: boolean;
  path: string;
};

const Header = () => {
  const { pathname } = useLocation();
  const user = useSelector(selectUserData);
  const {
    hasDocumentTemplateListRole,
    hasAiConfigurationListRole,
    hasPermGroupListRole,
    hasPermissionAnywhere,
    hasUiBuildInfoRole,
    hasCampaignTemplatesListRole,
  } = useUserHasPermission({});
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpened(true);
  };

  const navDataList = [
    hasPermissionAnywhere(UserPermissions.ROLE_COMPANY_LIST) && {
      label: "Companies",
      active: pathname.startsWith("/companies"),
      path: "/companies",
    },
    hasPermissionAnywhere(UserPermissions.ROLE_USER_LIST) && {
      label: "Users",
      active: pathname.startsWith("/users"),
      path: "/users",
    },
    hasPermissionAnywhere(UserPermissions.ROLE_CAMPAIGN_LIST) && {
      label: "Campaigns",
      active: pathname.startsWith("/campaigns"),
      path: "/campaigns",
    },
    (hasDocumentTemplateListRole || hasCampaignTemplatesListRole) && {
      label: "Templates",
      active: pathname.startsWith("/templates/"),
      path: hasDocumentTemplateListRole
        ? "/templates/documents"
        : "/templates/campaigns",
    },
    hasAiConfigurationListRole && {
      label: "Model Configs",
      active: pathname.startsWith("/model-configs"),
      path: "/model-configs",
    },
    hasPermGroupListRole && {
      label: "Security",
      active: pathname.startsWith("/security-groups"),
      path: "/security-groups",
    },
  ].filter(Boolean) as HeaderNavItem[];

  const menuItems: MenuProps["items"] = [
    ...navDataList.map(({ path, label, active }) => ({
      key: path,
      label: (
        <Link
          to={path}
          className={clsx(
            {
              "!text-primary": active,
            },
            "text-slate-700 text-sm font-semibold leading-[14px]",
          )}
        >
          {label}
        </Link>
      ),
      style: {
        display: "flex",
        alignItems: "center",
      },
    })),
  ];

  const activeNavKeys = navDataList
    .filter((item) => item.active)
    .map((item) => item.path);

  return (
    <>
      <div className="w-full h-[64px] flex items-center border-b border-[#E2E8F0] font-sans justify-between px-[48px]">
        <div className="w-full flex gap-2 items-center">
          <img src={logo} alt="App logo" />
          <Menu
            mode="horizontal"
            selectedKeys={activeNavKeys}
            items={menuItems}
            style={{
              height: "64px",
              border: "none",
              width: "70%",
            }}
            className="custom-menu flex gap-3"
          />
        </div>
        <div className="flex gap-[24px] items-center">
          <UserAvatar user={user} />
          {hasUiBuildInfoRole && (
            <VersionButton
              label={`v${packageJson.version}`}
              onClick={handleModalOpen}
            />
          )}
        </div>
      </div>
      {isModalOpened && hasUiBuildInfoRole && (
        <ReleasesPopup
          setIsModalOpened={setIsModalOpened}
          isModalOpened={isModalOpened}
        />
      )}
    </>
  );
};

export default Header;
