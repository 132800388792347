import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Modal, Table } from "antd";
import React, { FC, useState } from "react";
import {
  ComputedIcon,
  CreatedIcon,
  SolidErrorIcon,
} from "src/components/common/Icons";
import {
  TComputationMessage,
  TComputationStatus,
  TSocketMsgType,
} from "../../../globalTypes";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  getComputationStatus,
  getUpdateMessages,
} from "../../../store/slices/computationMessagesSlice";
import { Button } from "../../common/ui/button";
import ComputationProgress from "../ComputationProgress/ComputationProgress";
import { TComputationState } from "../../../store/slices/ccVariablesSlice";
import { stopExecuteCampaignApi } from "../../../api/campaigns.api";
import handleRequestError from "../../../utils/handleRequestError";
import { useUserHasPermission } from "src/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

const { Column } = Table;

const stateConfig: Record<
  string,
  { icon: React.ReactNode; className: string }
> = {
  processing: { icon: <LoadingOutlined />, className: "" },
  in_progress: { icon: <LoadingOutlined />, className: "" },
  computed: { icon: <ComputedIcon />, className: "text-[#16A34A]" },
  finished: { icon: <ComputedIcon />, className: "text-[#1c72d5]" },
  interrupted: { icon: <SolidErrorIcon />, className: "text-orange-600" },
  error: { icon: <SolidErrorIcon />, className: "text-red-600" },
  created: { icon: <CreatedIcon />, className: "text-[#D97706]" },
};

type Props = {
  onCloseAndClear: () => void;
  setIsListOfMessagesOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isListOfMessagesOpened: boolean;
  campaignId: number;
};

const ComputationMessagesModal: FC<Props> = ({
  onCloseAndClear,
  setIsListOfMessagesOpened,
  isListOfMessagesOpened,
  campaignId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const messageApi = getMessageApi();
  const computationStatus = getComputationStatus();
  const messagesMap = getUpdateMessages();
  const messages = Object.values(messagesMap);
  const { companyId } = useParams();
  const { hasCampaignComputationStopRole } = useUserHasPermission({
    companyId,
  });

  const onStopCampaignExecution = async () => {
    try {
      setIsLoading(true);
      await stopExecuteCampaignApi({ campaignId });

      messageApi.success(
        "Your request to stop the execution of the campaign has been sent",
      );
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Events log"
      open={isListOfMessagesOpened}
      onCancel={() => setIsListOfMessagesOpened(false)}
      width={1150}
      centered
      footer={
        <div className="flex justify-between items-end flex-wrap">
          <ComputationProgress />
          <div className="flex gap-[6px]">
            {computationStatus !== "finished" &&
              computationStatus !== "interrupted" &&
              hasCampaignComputationStopRole && (
                <Button
                  size="sm"
                  loading={isLoading}
                  onClick={onStopCampaignExecution}
                  className="rounded-full"
                  variant="outline"
                >
                  Stop Execution
                </Button>
              )}

            <Button
              size="sm"
              onClick={onCloseAndClear}
              className="rounded-full"
              variant="primaryOutline"
            >
              Clear and Close
            </Button>
          </div>
        </div>
      }
    >
      <div className="h-[550px]">
        {messages.length ? (
          <Table
            size="small"
            dataSource={messages}
            pagination={false}
            scroll={{ x: 1100, y: 500 }}
            virtual
          >
            <Column
              title="Type"
              dataIndex="type"
              key="type"
              width={200}
              render={(type: TSocketMsgType) => {
                let className =
                  "inline rounded-full px-2.5 py-0.5 font-semibold text-xs";

                switch (type) {
                  case "CI_UPDATED":
                    className += " bg-green-100 text-green-900";
                    break;
                  case "CI_ERROR":
                  case "PHASE_ERROR":
                  case "CAMPAIGN_ERROR":
                  case "STEP_ERROR":
                  case "DEPLOY_ERROR":
                  case "ASSET_ERROR":
                    className += " bg-red-100 text-red-900";
                    break;
                  case "POST_PROCESSING":
                    className += " bg-blue-100 text-blue-900";
                    break;
                  case "MICROSITE_DEPLOY":
                    className += " bg-purple-100 text-purple-900";
                    break;
                  default:
                    className += " bg-orange-100 text-orange-900";
                    break;
                }

                return <div className={className}>{type}</div>;
              }}
            />
            <Column
              title="Time"
              dataIndex="localTime"
              key="localTime"
              width={200}
            />
            <Column
              title="Item"
              dataIndex="path"
              key="key"
              render={(
                value: string | null,
                { errorMessage, computationTime, message }: TComputationMessage,
              ) => {
                let keyNode = (
                  <span className="font-semibold text-red-800">
                    Path not provided!
                  </span>
                );

                if (value) {
                  const splittedPath = value.split("/");

                  if (splittedPath.length > 1) {
                    const keyName = splittedPath.pop();
                    const firstPathPart = splittedPath.join("/") + "/";

                    keyNode = (
                      <div>
                        <span className="text-slate-300">{firstPathPart}</span>
                        <span className="font-semibold text-[#475569]">
                          {keyName}
                        </span>
                      </div>
                    );
                  } else {
                    keyNode = (
                      <span className="font-semibold text-[#475569]">
                        {value}
                      </span>
                    );
                  }
                }

                return (
                  <div className="flex flex-col">
                    <div className="flex items-center gap-[12px]">
                      {keyNode}

                      {computationTime !== undefined && (
                        <span className="text-blue-800 text-[12px]">
                          {`(${computationTime}ms)`}
                        </span>
                      )}
                    </div>

                    {message && (
                      <div className="text-blue-800 text-[12px]">{message}</div>
                    )}

                    {errorMessage && (
                      <div className="text-red-800 text-[12px]">
                        {errorMessage}
                      </div>
                    )}
                  </div>
                );
              }}
            />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              width={80}
              align="center"
              render={(state: TComputationState | TComputationStatus) => {
                const { icon, className } = stateConfig[state] || {
                  icon: <span>{state}</span>,
                  className: "",
                };

                return (
                  <div className={`flex justify-center ${className}`}>
                    {icon}
                  </div>
                );
              }}
            />
          </Table>
        ) : (
          <Empty
            imageStyle={{ height: "400px" }}
            className="font-sans font-semibold text-[14px]"
            description="There are no computation events"
          />
        )}
      </div>
    </Modal>
  );
};

export default ComputationMessagesModal;
