import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { useEffect, useState } from "react";
import { TCompanyShortInfo, TNavigationItem } from "../../../../globalTypes";
import handleRequestError from "../../../../utils/handleRequestError";
import { getCompanyDataApi } from "../../../../api/companies.api";
import { useUserHasPermission } from "../../../../hooks/useUserHasPermission";
import { useGoBack } from "src/hooks/useGoBack";

const useCompanyStorageData = () => {
  const { companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [companyData, setCompanyData] = useState<TCompanyShortInfo | null>(
    null,
  );
  const {
    hasPublicFsFileListRole,
    hasPrivateFsFileListRole,
    hasMsFsFileListRole,
  } = useUserHasPermission({ companyId });

  useEffect(() => {
    getCampaignData();
  }, []);

  const onGoBack = useGoBack("/campaigns/");

  const navigationItems: TNavigationItem[] = [
    hasPublicFsFileListRole && {
      path: "public",
      title: "Public",
    },
    hasPrivateFsFileListRole && {
      path: "private",
      title: "Private",
    },
    hasMsFsFileListRole && {
      path: "microsite",
      title: "Microsite",
    },
  ].filter(Boolean) as TNavigationItem[];

  const getCampaignData = async () => {
    if (!companyId) {
      messageApi.error("Unable to load the company view");
      console.error("Unable to get [companyId]");
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await getCompanyDataApi({ companyId });
      setError(null);
      setCompanyData(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
      setError(customError.message);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    error,
    companyName: companyData?.name,
    onGoBack,
    navigationItems,
  };
};
export default useCompanyStorageData;
