import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/common/Icons";
import { Button } from "../../components/common/ui/button";
import SaveTemplateButton from "../../components/docTemplates/SaveTemplateButton";
import TemplateSettingsForm from "../../components/docTemplates/TemplateSettingsForm";
import XSLTCodeEditor from "../../components/docTemplates/XSLTCodeEditor/XSLTCodeEditor";
import useTemplateDataHook from "./hooks/useTemplateData.hook";
import { useUserHasPermission } from "src/hooks/useUserHasPermission";

const TemplateDataPage = () => {
  const {
    XSLTCode,
    templateId,
    isFetching,
    setXSLTCode,
    form,
    setTab,
    tab,
    mediaType,
  } = useTemplateDataHook();
  const navigate = useNavigate();
  const { hasDocumentTemplateUpdateRole } = useUserHasPermission({});

  return (
    <Spin spinning={isFetching}>
      <div className="flex flex-col gap-[16px]">
        <div className="flex items-center justify-between">
          <div className="flex gap-[16px] items-center">
            <Button
              variant={"ghost"}
              icon={Icons.back}
              onClick={() => navigate(`/templates/documents`)}
              className="p-[12px] h-auto"
            />
            <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal">
              {templateId ? "Update Template" : "Create Template"}
            </h1>
          </div>
          {hasDocumentTemplateUpdateRole && (
            <SaveTemplateButton
              templateId={templateId}
              form={form}
              content={XSLTCode || ""}
            />
          )}
        </div>

        {!isFetching && (
          <div className="flex justify-between">
            <TemplateSettingsForm
              templateId={templateId}
              className="w-[370px]"
              form={form}
            />
            <XSLTCodeEditor
              setTab={setTab}
              tab={tab}
              setXSLTCode={setXSLTCode}
              XSLTCode={XSLTCode}
              className="w-[calc(100%-370px-32px)]"
              mediaType={mediaType}
            />
          </div>
        )}
      </div>
    </Spin>
  );
};

export default TemplateDataPage;
