import React, { FC } from "react";
import { Table, TableBody } from "../common/ui/table";
import { TableHeader } from "../table/Header";
import { TUserPermissionGroup } from "../../types/securityGroupes";
import PermissionListItem from "./PermissionListItem";
import { Empty } from "antd";

type Props = {
  userPermissions: TUserPermissionGroup[];
  updateList: () => Promise<void>;
};
const PermissionsList: FC<Props> = ({ userPermissions, updateList }) => {
  const tableHeaderItems = [
    {
      title: "Group",
      className: "font-sans w-1/3 text-black",
    },
    {
      title: "Super User",
      className: "font-sans w-1/3 text-black",
    },
    {
      title: "Level",
      className: "font-sans w-1/3 text-black",
    },
    null,
  ];

  if (!userPermissions.length)
    return (
      <Empty
        description={
          <div>
            <span className="font-sans font-medium text-[#0F172A]">
              There are no permission groups.
            </span>
          </div>
        }
      />
    );

  return (
    <div className="rounded-md border w-full mx-auto">
      <Table>
        <TableHeader items={tableHeaderItems} />
        <TableBody className="font-sans">
          {userPermissions.map((item) => (
            <PermissionListItem
              key={item.id}
              updateList={updateList}
              userPermissionGroup={item}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PermissionsList;
