import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "../pages/login/LoginPage";
import { getIsAuthenticated } from "../store/slices/userDataSlice";
import useTrackUrlChanges from "../hooks/useTrackUrlChanges";
import MainRoutes from "./MainRoutes";

const ApplicationRoutes = () => {
  const isAuthenticated = getIsAuthenticated();
  const location = useLocation();

  useTrackUrlChanges();

  return (
    <Routes location={location}>
      {isAuthenticated ? (
        <Route path="/*" element={<MainRoutes />} />
      ) : (
        <>
          <Route path="/sign-in" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </>
      )}
    </Routes>
  );
};

export default ApplicationRoutes;
