import React, { FC } from "react";
import { Button, Popover, Select } from "antd";
import useGetCompanySecurityGroups from "./useGetCompanySecurityGroups.hook";

type Props = {
  updateGroupsList: ({
    userId,
    update,
  }: {
    userId: string | number;
    update?: boolean | undefined;
  }) => Promise<void>;
};
const AddCompanySecurityGroup: FC<Props> = ({ updateGroupsList }) => {
  const {
    error,
    selectedGroupId,
    setSelectedGroupId,
    handleOpenChange,
    open,
    companyOptions,
    loadCompanies,
    selectedCompanyId,
    setSelectedCompanyId,
    globalGroupsOptions,
    onAddCompanyGroup,
    loading,
  } = useGetCompanySecurityGroups({ updateGroupsList });

  return (
    <div>
      <Popover
        placement="topRight"
        content={
          <div className="flex flex-col gap-[12px] w-[355px]">
            <div className="flex flex-col gap-[12px]">
              <div className="flex flex-col gap-[8px]">
                <span className="font-bold text-[14px] text-[#475569]">
                  Company
                </span>
                <Select
                  showSearch
                  options={companyOptions}
                  size="middle"
                  optionFilterProp="label"
                  defaultOpen={true}
                  autoFocus
                  placeholder="Select the company to add"
                  onChange={(value: number) => setSelectedCompanyId(value)}
                  value={selectedCompanyId}
                  className="w-[300px]"
                />
              </div>
              <div className="flex flex-col gap-[8px]">
                <span className="font-bold text-[14px] text-[#475569]">
                  Security Groups
                </span>
                <Select
                  options={globalGroupsOptions}
                  size="middle"
                  placeholder={
                    selectedCompanyId === null
                      ? "First, choose a company"
                      : "Select the group to add"
                  }
                  onChange={(value: number) => setSelectedGroupId(value)}
                  value={selectedGroupId}
                  disabled={selectedCompanyId === null}
                  loading={loading === "GROUPS"}
                  className="w-[300px]"
                />
              </div>

              <Button
                className="self-end ml-[12px]"
                size="small"
                type="primary"
                loading={loading === "SUBMIT"}
                disabled={!selectedGroupId || !selectedCompanyId}
                onClick={onAddCompanyGroup}
              >
                Save
              </Button>
            </div>
          </div>
        }
        trigger="click"
        open={open}
        destroyTooltipOnHide
        onOpenChange={handleOpenChange}
      />

      <Button
        type="primary"
        size="small"
        loading={loading === "COMPANIES"}
        onClick={loadCompanies}
      >
        Add Company Permissions
      </Button>

      {error && <div className="text-red-600 text-xs mt-1">{error}</div>}
    </div>
  );
};

export default AddCompanySecurityGroup;
