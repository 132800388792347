import React from 'react';
import { Result } from 'antd';

const ForbiddenPage = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you do not have permission to access this page."
    />
  );
};

export default ForbiddenPage;