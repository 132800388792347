import React, { FC } from "react";
import { Form, FormInstance, Input, Select } from "antd";
import {
  allowOnlyCharsDashAndQuotes,
  email,
  minMax,
  required,
} from "../../utils/validations";
import { SelectOption, TCompanyUserForm } from "../../globalTypes";
import customizeFormLabels from "../../utils/customizeFormLabels";

type Props = {
  form: FormInstance<TCompanyUserForm>;
  permissionGroupOptions: SelectOption[];
};

const CompanyUserForm: FC<Props> = ({ form, permissionGroupOptions }) => {
  return (
    <Form
      name="companyUserForm"
      form={form}
      layout="vertical"
      requiredMark={customizeFormLabels}
    >
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[
          required(),
          minMax({ text: "First name", min: 2, max: 40 }),
          allowOnlyCharsDashAndQuotes,
        ]}
      >
        <Input size="large" placeholder="Enter user first name" />
      </Form.Item>

      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[
          required(),
          minMax({ text: "User last name", min: 2, max: 80 }),
          allowOnlyCharsDashAndQuotes,
        ]}
      >
        <Input size="large" placeholder="Enter user last name" />
      </Form.Item>
      <Form.Item
        name="position"
        rules={[
          required(),
          minMax({ text: "User position", min: 2, max: 255 }),
        ]}
        label="Position/Title"
      >
        <Input size="large" placeholder="Enter user position" />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[required(), email, minMax({ text: "User email", max: 255 })]}
        label="Email"
      >
        <Input size="large" placeholder="Enter user email" />
      </Form.Item>
      <Form.Item
        name="permissionGroupId"
        label="Permission Group"
        rules={[{ required: true, message: "Required field!" }]}
      >
        <Select
          size="large"
          options={permissionGroupOptions}
          placeholder="Choose company permission group"
        />
      </Form.Item>
    </Form>
  );
};

export default CompanyUserForm;
