import React from "react";
import AddSecurityGroup from "../AddSecurityGroup/AddSecurityGroup";
import { Button, Empty, Skeleton, Spin } from "antd";
import UserCompanyPermissionTree from "./UserCompanyPermissionTree";
import useGetCompanyPermissions from "./useGetCompanyPermissions.hook";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

const UserCompanyPermissionData = () => {
  const {
    isInitialized,
    companyPermissionsData,
    error,
    isFetching,
    addGroupAndUpdateList,
    updatePermissionsList,
    deleteCompanyPermissionGroup,
    isUsersPage,
  } = useGetCompanyPermissions();
  const { companyId } = useParams();
  const {
    hasUserCompanyPermDeleteRole,
    hasUserCompanyPermCreateRole,
    hasUiShowDetailedPermissionsListRole,
  } = useUserHasPermission({ companyId });

  if (!isInitialized) return <Skeleton active />;

  if (!companyPermissionsData)
    return (
      <Empty
        description={
          <div>
            <span className="font-sans font-medium text-[#0F172A]">
              There is no company permissions.
            </span>
          </div>
        }
      />
    );

  const {
    globalPermissions,
    companyPermissions,
    companyPermissionGroups,
    globalPermissionGroups,
    companyPermissionsOverrides,
  } = companyPermissionsData;

  return (
    <div className="flex w-full justify-between">
      <div className="w-[39%] flex flex-col gap-[12px]">
        <div className="font-bold text-[16px] text-[#475569]">
          Company permission groups
        </div>

        {isUsersPage && (
          <div className="flex items-center gap-[8px] opacity-70">
            <div className="font-bold text-[12px] text-[#475569]">
              User global groups:
            </div>
            <div className="text-[12px] text-[#475569]">
              {globalPermissionGroups.length
                ? globalPermissionGroups.map(({ title }) => title).join(", ")
                : "not defined"}
            </div>
          </div>
        )}

        {hasUserCompanyPermCreateRole && (
          <AddSecurityGroup
            addGroupAndUpdateList={addGroupAndUpdateList}
            securityScope="COMPANY"
          />
        )}

        {error && <div className="text-red-600">{error}</div>}

        <Spin spinning={isFetching}>
          {companyPermissionGroups.length ? (
            <div className="flex flex-col w-full gap-[6px]">
              {companyPermissionGroups.map(({ id, title }) => {
                return (
                  <div key={id}>
                    <div key={id} className="flex justify-between gap-[12px]">
                      <div>{title}</div>

                      {hasUserCompanyPermDeleteRole && (
                        <Button
                          danger
                          size="small"
                          onClick={() =>
                            deleteCompanyPermissionGroup({
                              permissionsGroupId: id,
                            })
                          }
                          className="w-[60px]"
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                    <div className="h-[1px] bg-[#E2E8F0] w-[calc(100%-80px)] mt-[6px]" />
                  </div>
                );
              })}
            </div>
          ) : (
            <Empty
              description={
                <div>
                  <span className="font-sans font-medium text-[#0F172A]">
                    There are no security groups.
                  </span>
                </div>
              }
            />
          )}
        </Spin>
      </div>

      {hasUiShowDetailedPermissionsListRole && companyPermissions && (
        <>
          <div className="w-[1px] bg-[#E2E8F0]" />
          <div className="w-[58%]">
            <UserCompanyPermissionTree
              globalPermissions={globalPermissions}
              companyPermissions={companyPermissions}
              companyPermissionsOverrides={companyPermissionsOverrides}
              updatePermissionsList={updatePermissionsList}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UserCompanyPermissionData;
