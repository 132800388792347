import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "src/components/common/ui/button";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import {
  DownloadIcon,
  GlobalStorageIcon,
  Icons,
  LabelsIcon,
  MiniFolderIcon,
} from "../../common/Icons";
import ImportCampaignModal from "../ImportCampaignModal/ImportCampaignModal";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";
import StartCampaignModal from "../StartCampaignModal/StartCampaignModal";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { UserPermissions } from "../../../store/slices/userDataSlice";
import { TCampaignListOptionType } from "../../../globalTypes";
import { getMessageApi } from "../../../store/slices/appSlice";

type Props = {
  companyId: number | undefined;
  onCampaignCreate: () => Promise<void>;
  campaignType: TCampaignListOptionType;
};

const SettingsMenu: FC<Props> = ({
  onCampaignCreate,
  companyId,
  campaignType,
}) => {
  const navigate = useNavigate();
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStartCampaignModalOpen, setIsStartCampaignModalOpen] =
    useState(false);
  const {
    hasG18nListRole,
    hasPublicRootFsFileListRole,
    hasPrivateRootFsFileListRole,
    hasPermissionAnywhere,
  } = useUserHasPermission({});
  const isTemplatesPage = useLocation().pathname.includes("/templates");
  const {
    hasPublicFsFileListRole,
    hasPrivateFsFileListRole,
    hasCampaignCreateRole,
    hasCampaignStartRole,
    hasCampaignImportRole,
  } = useUserHasPermission({ companyId });
  const messageApi = getMessageApi();

  const actionsItems = [
    hasPermissionAnywhere(UserPermissions.ROLE_CAMPAIGN_IMPORT) && {
      key: "import",
      label: "Import Campaign",
      icon: DownloadIcon,
      disabled: companyId === undefined,
      onClick: () => setIsModalOpen(true),
    },
    hasG18nListRole && {
      key: "globalLabels",
      label: "Global Labels",
      icon: LabelsIcon,
      onClick: () => navigate("/campaigns/globalLabels"),
    },
    (hasPublicRootFsFileListRole || hasPrivateRootFsFileListRole) && {
      key: "globalStorage",
      label: "Global Storage",
      icon: GlobalStorageIcon,
      onClick: () => navigateWithOutletRedirect("/campaigns/storage"),
    },
    (hasPublicFsFileListRole || hasPrivateFsFileListRole) && {
      key: "companyStorage",
      label: "Company Storage",
      icon: MiniFolderIcon,
      disabled: !companyId,
      onClick: () =>
        navigateWithOutletRedirect(`/campaigns/company/${companyId}/storage`),
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <>
      <div className="flex gap-3 h-[40px]">
        {!!actionsItems.length && (
          <MenuDropdown items={actionsItems} dropdownClassName="w-[190px]">
            <Button variant={"primaryOutline"} className="text-sm font-medium">
              Actions
              <Icons.More className="size-4 ml-2" />
            </Button>
          </MenuDropdown>
        )}

        {isTemplatesPage && hasCampaignCreateRole && (
          <Button
            className="rounded-full"
            variant={"primaryOutline"}
            onClick={onCampaignCreate}
            disabled={campaignType === "PUBLISHED_TEMPLATE"}
          >
            <DocumentPlusIcon className="mr-[4px] size-4" />
            Add Draft Template
          </Button>
        )}

        {/* Need to show button if user has ROLE_CAMPAIGN_START permission anywhere*/}
        {hasPermissionAnywhere(UserPermissions.ROLE_CAMPAIGN_START) && (
          <Button
            className="rounded-full"
            onClick={() => {
              if (companyId === undefined) {
                messageApi.warning("Please select a company.");
                return;
              }
              setIsStartCampaignModalOpen(true);
            }}
          >
            <Icons.Plus className="mr-[4px] size-4" />
            Create Campaign
          </Button>
        )}
      </div>

      {companyId !== undefined && (
        <>
          {hasCampaignImportRole && isModalOpen && (
            <ImportCampaignModal
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              companyId={companyId}
            />
          )}

          {hasCampaignStartRole && isStartCampaignModalOpen && (
            <StartCampaignModal
              setIsModalOpen={setIsStartCampaignModalOpen}
              isModalOpen={isStartCampaignModalOpen}
              companyId={companyId}
            />
          )}
        </>
      )}
    </>
  );
};

export default SettingsMenu;
