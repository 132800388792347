import { Alert, Checkbox, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  createMicrositeApi,
  deleteMicrositeApi,
  getMicrositeApi,
} from "src/api/microsite.api";
import DeleteWithTitleConfirmationModal from "src/components/common/modals/DeleteWithTitleConfirmationModal/DeleteWithTitleConfirmationModal";
import { Button } from "src/components/common/ui/button";
import { selectMessageApi } from "src/store/slices/appSlice";
import handleRequestError from "src/utils/handleRequestError";
import { MicrositeState, TMicroSite } from "../../../globalTypes";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import {
  minMax,
  required,
  siteContextValidation,
  subDomainRule,
} from "../../../utils/validations";
import { Badge } from "../../common/ui/badge";
import { SftpUsers } from "../SftpUsers/SftpUsers";
import MicrositeStorage from "../../storage/micrositeStorage/MicrositeStorage";
import { CustomSiteStateBadge } from "./CustomSiteState";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import { MICRO_SITE_MAX_LENGTH, MICRO_SITE_POSTFIX } from "../../../constants";
import customizeFormLabels from "../../../utils/customizeFormLabels";

export type MicroSiteValues = {
  subDomain: string;
  siteContext: string;
};

const CustomerSite = ({
  companyName,
  companyId,
}: {
  companyName: string;
  companyId: number;
}) => {
  const messageApi = useSelector(selectMessageApi);
  const defaultSubDomain = companyName.toLowerCase();
  const [loading, setLoading] = useState<boolean>(false);
  const [micrositeId, setMicrositeId] = useState<number>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [enableHttps, setEnableHttps] = useState(false);
  const [metaInfo, setMetaInfo] = useState<string>("");
  const [state, setState] = useState<MicrositeState | null>(null);
  const [form] = Form.useForm<MicroSiteValues>();
  const {
    hasCompanyMicrositeCreateRole,
    hasCompanyMicrositeDeleteRole,
    hasCompanyMicrositeUserListRole,
    hasMsFsFileListRole,
  } = useUserHasPermission({ companyId });

  const setMicroSiteData = (microSiteData: TMicroSite | null) => {
    if (microSiteData) {
      const { id, siteContext, subDomain } = microSiteData;
      setMicrositeId(id);
      setMetaInfo(microSiteData.metaInfo);
      setState(microSiteData.state);
      setEnableHttps(microSiteData.enableHttps);
      form.setFieldsValue({ subDomain, siteContext });
    } else {
      form.setFieldsValue({ subDomain: defaultSubDomain, siteContext: "" });
    }
  };

  const getMicroSite = async () => {
    try {
      setLoading(true);
      const { data } = await getMicrositeApi({ companyId });

      setMicroSiteData(data);
    } catch (error: any) {
      const customError = handleRequestError(error);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMicroSite();
  }, []);

  const listenMicrositeState = async () => {
    try {
      const { data } = await getMicrositeApi({ companyId });

      if (data) {
        setState(data.state);
      }
    } catch (error: any) {
      const customError = handleRequestError(error);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  useEffect(() => {
    if (state === "PENDING_VALIDATION") {
      const interval = setInterval(() => {
        listenMicrositeState();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [state]);

  const handleCreateMicroSite = async (values: MicroSiteValues) => {
    try {
      setLoading(true);
      const { subDomain, siteContext } = values;
      const { data } = await createMicrositeApi({
        subDomain,
        siteContext,
        enableHttps,
        companyId,
      });

      setMicroSiteData(data);
      messageApi.success("Micro site is in pending validation state");
    } catch (error: any) {
      const customError = handleRequestError(error);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMicroSite = async () => {
    try {
      setLoading(true);
      const subDomain = form.getFieldValue("subDomain");

      await deleteMicrositeApi({ companyId, subDomain });

      form.setFieldsValue({
        subDomain: defaultSubDomain,
        siteContext: "",
      });

      setState(null);
      setMicrositeId(undefined);
      setIsDeleteModalOpen(false);
    } catch (error: any) {
      const customError = handleRequestError(error);

      messageApi.error(customError.message);
    } finally {
      setLoading(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: MicroSiteValues) => {
      await handleCreateMicroSite(values);
    },
  });

  const validLength = MICRO_SITE_MAX_LENGTH - MICRO_SITE_POSTFIX.length;

  return (
    <div className="flex flex-col gap-[24px]">
      <div className="flex justify-between items-center">
        <div className="w-[400px]">
          {!state ? (
            <>
              <div className="flex flex-col gap-[2px]">
                <div className="font-bold text-[#0F172A] text-[20px]">
                  Microsite
                </div>
                <div className="font-sans text-[#475569] text-[14px]">
                  Create a customer subdomain
                </div>
              </div>
              <Form
                form={form}
                layout="vertical"
                className="w-[373px] mt-[20px]"
                requiredMark={customizeFormLabels}
              >
                <Form.Item
                  name="subDomain"
                  label="Subdomain"
                  rules={[
                    required(),
                    subDomainRule,
                    minMax({ text: "Subdomain", max: validLength }),
                  ]}
                >
                  <Input
                    size="large"
                    addonAfter={<span>{MICRO_SITE_POSTFIX}</span>}
                    disabled={!hasCompanyMicrositeCreateRole || loading}
                    classNames={{
                      input: "h-[48px] px-5 py-2",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="siteContext"
                  tooltip="String must be 255 characters or less"
                  label="Site Context"
                  rules={[
                    minMax({ text: "Site Context", max: 255 }),
                    siteContextValidation,
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="path/to/your-site"
                    disabled={!hasCompanyMicrositeCreateRole || loading}
                    className="h-[48px] px-5 py-2"
                  />
                </Form.Item>
                <Checkbox
                  className="mb-5"
                  onChange={(e) => setEnableHttps(e.target.checked)}
                  checked={enableHttps}
                  disabled={!hasCompanyMicrositeCreateRole || loading}
                >
                  Enable HTTPS
                </Checkbox>
              </Form>
              {hasCompanyMicrositeCreateRole && (
                <Button
                  className="rounded-full px-6"
                  onClick={submitForm}
                  disabled={loading}
                  loading={loading}
                >
                  Create
                </Button>
              )}
            </>
          ) : (
            <div className="flex flex-col items-start">
              <div className="font-bold text-[#475569] text-[14px] inline-flex">
                Microsite <CustomSiteStateBadge state={state} />
                <Badge
                  className={`ml-2 ${enableHttps ? "!text-green-500 !bg-green-100" : "!text-red-900 !bg-red-100"}`}
                >
                  HTTPS {enableHttps ? "Enabled" : "Disabled"}
                </Badge>
              </div>
              <Link
                className="flex justify-center items-center gap-2 text-[16px] text-[#4F46E5]"
                to={`${enableHttps ? "https" : "http"}://${form.getFieldValue("subDomain")}${MICRO_SITE_POSTFIX}/${form.getFieldValue("siteContext")}`}
                target="_blank"
                rel="noreferrer"
              >
                Visit {form.getFieldValue("subDomain")}
                {MICRO_SITE_POSTFIX}/{form.getFieldValue("siteContext")}
              </Link>
            </div>
          )}
        </div>
        {hasCompanyMicrositeDeleteRole && state && (
          <Button
            variant="destructive"
            className="rounded-full px-6"
            onClick={() => setIsDeleteModalOpen(true)}
            loading={loading}
            disabled={loading}
          >
            Delete
          </Button>
        )}
      </div>
      {metaInfo &&
        ["ERROR", "PENDING_VALIDATION"].includes(state as MicrositeState) && (
          <Alert
            message={metaInfo}
            type={state === "ERROR" ? "error" : "info"}
          />
        )}
      <div className="h-[1px] bg-[#E2E8F0]" />
      <div>
        {["CREATED"].includes(state as MicrositeState) && micrositeId && (
          <div className="flex flex-wrap justify-between gap-4">
            {hasCompanyMicrositeUserListRole && (
              <div className="w-full lg:w-[51%] mb-4">
                <SftpUsers micrositeId={micrositeId} />
              </div>
            )}
            {hasMsFsFileListRole && (
              <div className="w-full lg:w-[47%]">
                <MicrositeStorage />
              </div>
            )}
          </div>
        )}
      </div>

      {hasCompanyMicrositeDeleteRole && isDeleteModalOpen && (
        <DeleteWithTitleConfirmationModal
          entity="microsite"
          onDelete={() => handleRemoveMicroSite()}
          title={form.getFieldValue("subDomain")}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}
    </div>
  );
};

export default CustomerSite;
