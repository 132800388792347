import React, { FC, useState } from "react";
import { DataNode } from "antd/lib/tree";
import { Spin, Tree } from "antd";
import { Icons } from "../../common/Icons";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { TPermissionOverride } from "../../../globalTypes";
import {
  createCompanySecurityPermissionOverrideApi,
  deleteCompanySecurityPermissionOverrideApi,
} from "../../../api/company-permissions-override.api";
import {
  selectPermissionsTree,
  UserPermissions,
} from "../../../store/slices/userDataSlice";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";

type Props = {
  globalPermissions: UserPermissions[];
  companyPermissions: UserPermissions[];
  companyPermissionsOverrides: TPermissionOverride;
  updatePermissionsList: () => Promise<void>;
};

const { denyOverrideIcon, allowOverrideIcon } = Icons;

const PermissionsTree: FC<Props> = ({
  globalPermissions,
  companyPermissionsOverrides,
  updatePermissionsList,
  companyPermissions,
}) => {
  const { userId, companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const { companyLevel: permissionsTree } = useSelector(selectPermissionsTree);
  const [fetchingId, setFetchingId] = useState<UserPermissions | null>(null);
  const { hasCompanyPermOvrCreateRole, hasCompanyPermOvrDeleteRole } =
    useUserHasPermission({});

  //if accessType provided - create override, otherwise delete override
  const doOverride = async (
    permission: UserPermissions,
    accessType?: "ALLOW" | "DENY",
  ) => {
    try {
      if (userId !== undefined && companyId !== undefined) {
        setFetchingId(permission);

        if (accessType) {
          await createCompanySecurityPermissionOverrideApi({
            accessType,
            permission,
            userId,
            companyId,
          });
        } else {
          await deleteCompanySecurityPermissionOverrideApi({
            permission,
            userId,
            companyId,
          });
        }
        await updatePermissionsList();
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setFetchingId(null);
    }
  };

  const getIcon = ({
    perm,
    icon,
    action,
  }: {
    perm: UserPermissions;
    action?: "ALLOW" | "DENY";
    icon: React.ReactNode;
  }) => {
    const iconWrapClassName =
      "flex justify-center items-center w-[18px] h-[18px] border-[1px] transition-colors rounded p-[1px] hover:bg-[#eee]";
    return (
      <div
        className={iconWrapClassName}
        onClick={() => doOverride(perm, action)}
      >
        {icon}
      </div>
    );
  };

  const getPermissionTags = ({
    perm,
    companyPermissions,
    globalPermissions,
  }: {
    perm: UserPermissions;
    globalPermissions: UserPermissions[];
    companyPermissions: UserPermissions[];
  }) => {
    const tags = [];
    if (globalPermissions.includes(perm)) {
      tags.push(
        <div
          key="G"
          className="w-[14px] h-[14px] flex justify-center items-center text-[10px] text-[#fff] font-bold bg-[#4F46E5] rounded"
        >
          G
        </div>,
      );
    }
    if (companyPermissions.includes(perm)) {
      tags.push(
        <div
          key="C"
          className="w-[14px] h-[14px] flex justify-center items-center text-[10px] text-[#fff] font-bold bg-fuchsia-600 rounded"
        >
          C
        </div>,
      );
    }
    return tags;
  };

  const getTitle = ({
    perm,
    companyPermissionsOverrides,
    globalPermissions,
    companyPermissions,
    fetchingId,
  }: {
    perm: UserPermissions;
    companyPermissionsOverrides: TPermissionOverride;
    globalPermissions: UserPermissions[];
    companyPermissions: UserPermissions[];
    fetchingId: UserPermissions | null;
  }) => {
    const isFetching = perm === fetchingId;
    const tags = getPermissionTags({
      perm,
      globalPermissions,
      companyPermissions,
    });

    //by default assume that permission doesn't exist, show allow button if the user hase "hasCompanyPermOvrCreateRole" role
    let iconElement = hasCompanyPermOvrCreateRole
      ? getIcon({
          perm,
          action: "ALLOW",
          icon: allowOverrideIcon,
        })
      : null;
    let textColor = "text-[#CBCACA]";
    let fontStyle = "";

    //if global permission exists, show deny button if the user hase "hasCompanyPermOvrCreateRole" role
    if (globalPermissions.includes(perm)) {
      iconElement = hasCompanyPermOvrCreateRole
        ? getIcon({ perm, action: "DENY", icon: denyOverrideIcon })
        : null;
      textColor = "text-[#4F46E5]";
      fontStyle = "font-medium";
    }

    //if global permission exists, show deny button if the user hase "hasCompanyPermOvrCreateRole" role
    if (companyPermissions.includes(perm)) {
      iconElement = hasCompanyPermOvrCreateRole
        ? getIcon({ perm, action: "DENY", icon: denyOverrideIcon })
        : null;
      textColor = "text-fuchsia-600";
      fontStyle = "font-medium";
    }

    //if permission allowed, show delete override button if the user hase "hasCompanyPermOvrDeleteRole" role
    if (companyPermissionsOverrides[perm] === "ALLOW") {
      iconElement = hasCompanyPermOvrDeleteRole
        ? getIcon({ perm, icon: denyOverrideIcon })
        : null;
      textColor = "text-green-600";
      fontStyle = "font-medium";
    }

    //if permission denied, show delete override button if the user hase "hasCompanyPermOvrDeleteRole" role
    if (companyPermissionsOverrides[perm] === "DENY") {
      iconElement = hasCompanyPermOvrDeleteRole
        ? getIcon({ perm, icon: allowOverrideIcon })
        : null;
      textColor = "text-red-600";
      fontStyle = "font-medium";
    }

    const titleContent = (
      <>
        {iconElement}
        <span className={`text-[12px] ${textColor} ${fontStyle}`}>{perm}</span>
        {tags}
      </>
    );

    return (
      <Spin spinning={isFetching}>
        <div className="flex gap-[8px] items-center">{titleContent}</div>
      </Spin>
    );
  };

  const getTreeData = (): DataNode[] => {
    return Object.keys(permissionsTree).map((key) => ({
      title: <span className="font-semibold">{key}</span>,
      key: key,
      children: permissionsTree[key].map((perm) => ({
        title: getTitle({
          perm,
          companyPermissionsOverrides,
          globalPermissions,
          companyPermissions,
          fetchingId,
        }),
        key: perm,
      })),
    }));
  };

  return (
    <div className="flex flex-col gap-[12px]">
      <span className="font-bold text-[16px] text-[#475569]">
        Company user permissions
      </span>
      <Tree selectable={false} treeData={getTreeData()} defaultExpandAll />
    </div>
  );
};

export default PermissionsTree;
