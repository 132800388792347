import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCampaignDataApi } from "../../../../api/campaigns.api";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import {
  getCurrentCampaign,
  setCurrentCampaign,
} from "../../../../store/slices/campaignsSlice";
import handleRequestError from "../../../../utils/handleRequestError";
import { TNavigationItem } from "../../../../globalTypes";
import { getCompanyApi } from "../../../../api/companies.api";
import { AppDispatch } from "../../../../store/store";
import { setCurrentCompany } from "../../../../store/slices/companiesSlice";
import { useUserHasPermission } from "../../../../hooks/useUserHasPermission";

const useCampaignStorageData = () => {
  const { companyId, campaignId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const messageApi = useSelector(selectMessageApi);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const currentCampaign = getCurrentCampaign();
  const {
    hasPublicFsFileListRole,
    hasPrivateFsFileListRole,
    hasCampaignReadRole,
    hasMsFsFileListRole,
  } = useUserHasPermission({ companyId });

  useEffect(() => {
    getCampaignData();
  }, []);

  const onGoBack = () => {
    location.key !== "default"
      ? navigate(-1)
      : navigate(`/campaigns/company/${companyId}/campaign/${campaignId}/`);
  };

  const navigationItems = [
    hasPublicFsFileListRole && {
      path: "public",
      title: "Public",
    },
    hasPrivateFsFileListRole && {
      path: "private",
      title: "Private",
    },
    hasCampaignReadRole && {
      path: "assets",
      title: "Assets",
    },
    hasMsFsFileListRole && {
      path: "microsite",
      title: "Microsite",
    },
  ].filter(Boolean) as TNavigationItem[];

  const getCampaignData = async () => {
    if (!campaignId || !companyId) {
      messageApi.error("Unable to load the campaign view");
      console.error("Unable to get [companyId] or [campaignId]");
      setIsLoading(false);
      return;
    }

    try {
      const [{ data: company }, { data: campaign }] = await Promise.all([
        getCompanyApi({ companyId }),
        getCampaignDataApi({
          campaignId,
        }),
      ]);

      dispatch(setCurrentCompany(company));
      dispatch(setCurrentCampaign(campaign));

      setError(null);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
      setError(customError.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    onGoBack,
    navigationItems,
    currentCampaign,
  };
};
export default useCampaignStorageData;
