import React from "react";
import { Outlet } from "react-router-dom";
import { TNavigationItem } from "../../globalTypes";
import { Button } from "../../components/common/ui/button";
import { Icons } from "../../components/common/Icons";
import Navigation from "../../components/common/Navigation";
import { useUserHasPermission } from "../../hooks/useUserHasPermission";
import useRedirectToFirstChildOutlet from "../../hooks/navigation/useRedirectToFirstChildOutlet";
import { useGoBack } from "src/hooks/useGoBack";

const GlobalStoragePage = () => {
  const { hasPublicRootFsFileListRole, hasPrivateRootFsFileListRole } =
    useUserHasPermission({});

  const goBack = useGoBack("/campaigns/");

  const navigationItems: TNavigationItem[] = [
    hasPublicRootFsFileListRole && {
      path: "public",
      title: "Public",
    },
    hasPrivateRootFsFileListRole && {
      path: "private",
      title: "Private",
    },
  ].filter(Boolean) as TNavigationItem[];

  useRedirectToFirstChildOutlet({ navigationItems });

  return (
    <div>
      <div className="flex gap-[12px] items-center">
        <Button
          variant={"ghost"}
          icon={Icons.back}
          onClick={goBack}
          className="p-[12px] h-auto"
        />
        <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal capitalize">
          Global Storage
        </h1>
      </div>
      <Navigation items={navigationItems} />
      <Outlet />
    </div>
  );
};
export default GlobalStoragePage;
