import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  createCampaignApi,
  editCampaignApi,
  getCampaignsDataApi,
} from "../../api/campaigns.api";
import { getGlobalLabelsApi } from "../../api/global-labels.api";
import { getStepPathMap } from "../../utils/cm.utils";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import transformCampaignData from "../../utils/transformCampaignData";
import { RootState } from "../store";
import { toggleIsFetching } from "./appSlice";
import { setCampaignLabels } from "./campaignLabelsSlice";
import { loadDynamicItemsThunk, setCcVariables } from "./ccVariablesSlice";
import { setGlobalLabels } from "./globalLabelsSlice";
import { cleanUpPhases, setCurrentPhase, setPhases } from "./phasesSlice";
import {
  cleanUpSteps,
  setCurrentStep,
  setStepPathMap,
  setSteps,
  TStep,
} from "./stepsSlice";
import { CampaignViewTypes } from "../../types";
import { TAsset, TModifiedInfo, TPhase } from "../../globalTypes";

export type TCampaign = TModifiedInfo & {
  id: number;
  companyId: number;
  companyName: string | null;
  naturalId: string;
  type: TCampaignType;
  name: string;
  microSiteContextFolder: null | string;
  aiModelId?: number | null;
  companyLogo: string | null;
  modifiedById: number | null;
  modifiedByName: string | null;
  modifiedTime: string | null;
  archive: boolean;
};

export type CampaignDataAssetsStructure = TCampaign & {
  phases: (TPhase & { steps: (TStep & { assets: TAsset[] })[] })[];
};

export type TCampaignType =
  | "CAMPAIGN"
  | "DRAFT_TEMPLATE"
  | "PUBLISHED_TEMPLATE";

//TODO check TCampaignResponseData - response changed with new values
export type TCampaignResponseData = Omit<TCampaign, "companyName">;

const initialState = {
  currentView: CampaignViewTypes.GRID,
  current: null as TCampaign | null,
  list: [] as Array<TCampaign>,
};

type InitialStateType = typeof initialState;

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCampaigns: (
      state: InitialStateType,
      action: PayloadAction<Array<TCampaign>>,
    ) => {
      state.list = action.payload;
    },
    addCampaign: (
      state: InitialStateType,
      action: PayloadAction<TCampaign>,
    ) => {
      state.list.push(action.payload);
    },
    setCurrentCampaignView: (
      state: InitialStateType,
      action: PayloadAction<CampaignViewTypes>,
    ) => {
      state.currentView = action.payload;
    },
    setCurrentCampaign: (
      state: InitialStateType,
      action: PayloadAction<TCampaign | null>,
    ) => {
      state.current = action.payload;
    },
    updateCampaign: (
      state: InitialStateType,
      action: PayloadAction<TCampaign>,
    ) => {
      const editedCampaign = action.payload;
      const index = state.list.findIndex(
        (campaign) => campaign.id === editedCampaign.id,
      );

      state.list[index] = editedCampaign;
    },
  },
});

export const {
  setCampaigns,
  addCampaign,
  setCurrentCampaign,
  updateCampaign,
  setCurrentCampaignView,
} = campaignsSlice.actions;

export default campaignsSlice.reducer;

export const selectCampaignsList = (state: RootState) => state.campaigns.list;
export const selectCurrentCampaign = (state: RootState) =>
  state.campaigns.current;
export const selectCurrentCampaignView = (state: RootState) =>
  state.campaigns.currentView;

/* eslint-disable*/
export const useCampaigns = (): TCampaign[] => useSelector(selectCampaignsList);
export const getCurrentCampaign = (): TCampaign | null =>
  useSelector(selectCurrentCampaign);

type TLoadCampaignDataThunk = {
  companyId: number;
  campaignId: number;
  phaseId: number | undefined;
  stepId: number | undefined;
};
export const loadCampaignDataThunk = createAsyncThunk<
  string | undefined,
  TLoadCampaignDataThunk,
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "Campaigns/loadCampaignDataThunk",
  async (
    { campaignId, phaseId, stepId, companyId },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: campaignData } = await getCampaignsDataApi({ campaignId });
      const { data: globalLabels } = await getGlobalLabelsApi({ lang: "en" });
      const { campaign, phases, steps, labels, step, phase } =
        transformCampaignData(campaignData, phaseId, stepId);
      const stepPathMap = getStepPathMap(campaignData.phases);

      await dispatch(loadDynamicItemsThunk()).unwrap();

      dispatch(setStepPathMap(stepPathMap));

      dispatch(setCurrentStep(step));
      dispatch(setSteps(steps));

      dispatch(setPhases(phases));
      dispatch(setCurrentPhase(phase));

      dispatch(setCurrentCampaign(campaign));
      // dispatch(setCurrentCompany(currentCompany));

      dispatch(setCampaignLabels(labels));
      dispatch(setGlobalLabels(globalLabels));

      if (phase && step) {
        //return the full URL if the phase and step have been successfully loaded
        return `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phase.id}/step/${step.id}`;
      }

      if (!phase) {
        //return the redirect path to the campaign if the phase has not been loaded
        return `/campaigns/company/${companyId}/campaign/${campaignId}`;
      } else if (!step) {
        //return the redirect path to the phase if the step has not been loaded
        return `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phase.id}`;
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to load campaign data:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

export const createCampaignThunk = createAsyncThunk<
  TCampaign,
  { companyId: number },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "Campaigns/createCampaign",
  async ({ companyId }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const campaignType = "DRAFT_TEMPLATE";
      const formattedDate = new Date()
        .toLocaleString()
        .replace(/\//g, "-")
        .replace(/:/g, "-")
        .replace(/, /g, "_");
      const name = `Untitled_(${formattedDate})`;

      const { data } = await createCampaignApi({
        name,
        type: campaignType,
        companyId,
      });

      await dispatch(loadDynamicItemsThunk()).unwrap();

      dispatch(setCurrentCampaign(data));
      dispatch(cleanUpPhases());
      dispatch(cleanUpSteps());
      dispatch(setCcVariables([]));
      dispatch(addCampaign(data));

      return data;
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to create the campaign:`,
        e,
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

export const editCampaignThunk = createAsyncThunk<
  void,
  { campaign: TCampaign; rebuild?: boolean },
  { rejectValue: TCustomError }
>(
  "Campaigns/updateCampaign",
  async ({ campaign, rebuild }, { rejectWithValue, dispatch }) => {
    try {
      await editCampaignApi({ campaignData: campaign, rebuild });

      dispatch(setCurrentCampaign(campaign));
      dispatch(updateCampaign(campaign));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to update the campaign:`,
        e,
      );

      return rejectWithValue(customError);
    }
  },
);

export const updateCampaignView = createAsyncThunk<
  string,
  { viewType: CampaignViewTypes },
  { state: RootState; rejectValue: TCustomError }
>("steps/updateCampaignViewType", ({ viewType }, { getState, dispatch }) => {
  dispatch(toggleIsFetching(true));

  const { steps } = getState();

  if (steps.current?.hiddenDocView && viewType === CampaignViewTypes.DOC) {
    const firstStepVisibleDoc =
      steps.list.find((step) => !step.hiddenDocView) || null;

    dispatch(setCurrentStep(firstStepVisibleDoc));
    dispatch(setCurrentCampaignView(CampaignViewTypes.DOC));
  } else {
    dispatch(setCurrentCampaignView(viewType));
  }

  dispatch(toggleIsFetching(false));

  return "success";
});
