import { setIsAuthenticated } from "../../store/slices/userDataSlice";
import instance from "./axiosInstance";

const axiosInterceptor = (store: any) => {
  instance.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err?.response?.status === 401) {
        store.dispatch(setIsAuthenticated(false));
      }
      return Promise.reject(err);
    },
  );
};

export default axiosInterceptor;
