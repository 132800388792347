import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setDefaultSystemModelConfigApi } from "src/api/model-configs.api";
import { DeleteIcon, Icons } from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import { TableCell, TableRow } from "src/components/common/ui/table";
import useConfirm from "src/hooks/useConfirm";
import { useModelConfigsList } from "src/hooks/useModelConfigsList";
import { getMessageApi } from "src/store/slices/appSlice";
import { ModelConfig } from "src/types/modelConfigs";
import DeleteModelConfigModal from "../common/modals/DeleteModelConfigModal/DeleteModelConfigModal";
import { Checkbox } from "../common/ui/checkbox";
import { useUserHasPermission } from "../../hooks/useUserHasPermission";
import { ArrowRightIcon } from '../common/Icons';

type Props = {
  item: ModelConfig;
};

const ModelConfigListItem: FC<Props> = ({ item }) => {
  const {
    id,
    title,
    serviceProvider,
    modelName,
    readTimoutSec,
    systemDefault,
    maxTokens,
    topP,
    temperature,
    retriesOnErrorResponse,
  } = item;
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const confirm = useConfirm();
  const { load, updateSystemDefault } = useModelConfigsList();
  const {
    hasAiConfigurationReadRole,
    hasAiConfigurationDeleteRole,
    hasAiConfigurationSetDefaultRole,
  } = useUserHasPermission({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigateToEdit = () => {
    navigate(`/model-configs/${id}`);
  };

  const menuItems = [
    hasAiConfigurationReadRole && {
      key: "edit",
      label: "Details",
      onClick: navigateToEdit,
      icon: ArrowRightIcon,
    },
    hasAiConfigurationDeleteRole &&
      !systemDefault && {
        key: "delete",
        label: "Delete",
        onClick: () => {
          setIsDeleteModalOpen(true);
        },
        icon: DeleteIcon,
        className: "!text-red-600",
      },
  ].filter(Boolean) as MenuDropdownItem[];

  const handleSetSystemDefault = async () => {
    try {
      await setDefaultSystemModelConfigApi(id);
      updateSystemDefault(id);
      messageApi.success(`${title} set as system default`);
    } catch (error) {
      console.error("Error setting system default model config", error);
      messageApi.error("Error setting system default model config");
    }
  };

  const onCheckSystemDefault = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    if (systemDefault) {
      confirm({
        title: "Already System Default",
        action: () => {},
        content:
          "You cannot deselect this model config, you need to select another model config as system default.",
      });
      return;
    }
    confirm({
      title: "Set System Default",
      action: handleSetSystemDefault,
    });
  };

  return (
    <>
      <TableRow
        key={id}
        className="cursor-pointer relative h-[48px]"
        onClick={hasAiConfigurationReadRole ? navigateToEdit : undefined}
      >
        <TableCell>
          <span className="text-sm font-semibold">{title}</span>
        </TableCell>
        <TableCell>
          <span className="text-sm text-[#475569]">
            {serviceProvider || "-"}
          </span>
        </TableCell>
        <TableCell>
          <span className="text-sm text-[#475569]">{modelName || "-"}</span>
        </TableCell>
        <TableCell>
          <span className=" text-sm text-[#475569]">{readTimoutSec}</span>
        </TableCell>
        <TableCell>
          <span className="text-sm text-[#475569]">{maxTokens}</span>
        </TableCell>
        <TableCell>
          <span className="text-sm text-[#475569]">{topP}</span>
        </TableCell>
        <TableCell>
          <span className="text-sm text-[#475569]">{temperature}</span>
        </TableCell>
        <TableCell>
          <span className="text-sm text-[#475569]">{retriesOnErrorResponse}</span>
        </TableCell>

        <TableCell>
          <div className="text-sm relative">
            <Checkbox
              onClick={
                hasAiConfigurationSetDefaultRole
                  ? onCheckSystemDefault
                  : undefined
              }
              checked={!!systemDefault}
              disabled={!hasAiConfigurationSetDefaultRole}
            />
          </div>
        </TableCell>
        {menuItems.length ? (
          <TableCell onClick={(e) => e.stopPropagation()}>
            <MenuDropdown
              items={menuItems}
              dropdownClassName="w-[190px]"
              align="end"
            >
              <Button
                variant={"ghostPrimary"}
                className="size-[40px] p-2 flex justify-end"
              >
                <Icons.MoreDots className="size-[24px]" />
              </Button>
            </MenuDropdown>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
      {hasAiConfigurationDeleteRole && isDeleteModalOpen && (
        <DeleteModelConfigModal
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
          aiModelId={item.id}
          onDeleted={() => {
            load();
          }}
        />
      )}
    </>
  );
};

export default ModelConfigListItem;
