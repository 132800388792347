import React from "react";
import CompanyUsersList from "../../../components/companies/CompanyUsersList/CompanyUsersList";
import useGetCompanyUsers from "./useGetCompanyUsers.hook";
import { Skeleton } from "antd";
import AddCompanyUser from "../../../components/companies/AddCompanyUser";

const CompanyUsersPage = () => {
  const { isInitialized, error, companyUsers, loadUsers } =
    useGetCompanyUsers();

  if (!isInitialized) return <Skeleton active />;

  return (
    <div className="flex flex-col gap-6">
      <AddCompanyUser loadUsers={loadUsers} />

      <div>
        {error ? (
          <div className="text-red-600">{error}</div>
        ) : (
          <CompanyUsersList users={companyUsers} loadUsers={loadUsers} />
        )}
      </div>
    </div>
  );
};

export default CompanyUsersPage;
