import { Rule } from "antd/es/form";
import { TEditModeProps } from "../components/CampaignGrid/Grid/CampaignGrid";
//TODO revise and apply rules
type minMaxType = {
  text?: string;
  min?: number;
  max?: number;
  measuredValue?: string;
};

// rule to satisfy
export const subDomainRule = {
  message:
    "The subdomain must contain only lowercase letters, digits, and hyphens, and must not start or end with a hyphen.",
  validator: (_: any, value: string) => {
    const trimmed = value.trim();

    if (trimmed.startsWith("-") || trimmed.endsWith("-")) {
      return Promise.reject(
        new Error("Field should not start or end with a hyphen!"),
      );
    }

    if (!/^[a-z0-9-]+$/.test(trimmed)) {
      return Promise.reject(
        new Error(
          "Field should contain only lowercase letters, digits, and hyphens! Hyphens should not be in the start or end of the field.",
        ),
      );
    }

    return Promise.resolve();
  },
};

export const noSpecialSymbols = {
  pattern: /^(?!-)[a-z0-9]+(\.[a-z0-9-]+)*(\.[a-z]{2,})?$/,
  message:
    "The field must contain only lowercase letters, digits, and dots, and must not start or end with a hyphen.",
};

export const allowOnlyCharsDashAndQuotes = {
  pattern: /^[A-Za-z\s\-`'\u00C0-\u00FF]+$/,
  message: "Allowed characters: A-Z, a-z, -, ', `",
};

export const noSlashes = {
  pattern: /^[^\\/]*$/,
  message: "The field must not contain slashes!",
};

export const variableName = () => ({
  pattern: /^[a-zA-Z_$][a-zA-Z0-9_$]*$/,
  message: "Invalid JavaScript variable name!",
});

export const alreadyExist = (
  key: string | undefined,
  varKeysLowerCase: Array<string>,
): Rule => ({
  validator: (_, value) => {
    const trimmed = value ? value.trim() : "";
    const lowerCaseValue = trimmed.toLowerCase();

    if (
      key?.toLowerCase() !== lowerCaseValue &&
      varKeysLowerCase.includes(lowerCaseValue)
    ) {
      return Promise.reject(new Error("The value already exists!"));
    }

    return Promise.resolve();
  },
});

export const startWithoutSlash: Rule = () => ({
  validator(_, value) {
    const trimmed = value ? value.trim() : "";

    if (trimmed[0] === "/") {
      return Promise.reject(
        new Error("Site Context shouldn't start with a slash"),
      );
    }

    return Promise.resolve();
  },
});

export const siteContextValidation = {
  validator(_: any, value: string) {
    if (!value) {
      return Promise.resolve();
    }
    if (value.startsWith("/") || value.endsWith("/")) {
      return Promise.reject(
        new Error("Site Context must not start or end with a slash."),
      );
    }
    if (/\/{2,}/.test(value)) {
      return Promise.reject(
        new Error("Site Context must not contain consecutive slashes."),
      );
    }
    if (!/^[a-zA-Z0-9/-]*$/.test(value)) {
      return Promise.reject(
        new Error(
          "Site Context can only contain Latin letters, numbers, slashes, and hyphens.",
        ),
      );
    }
    return Promise.resolve();
  },
};

export const lowerCase: Rule = {
  validator: (_, value) => {
    if (value && value !== value.toLowerCase()) {
      return Promise.reject(new Error("The value must be in lowercase!"));
    }
    return Promise.resolve();
  },
};

export const folderName = {
  pattern: /^[a-zA-Z0-9-_]+$/,
  message: "Invalid characters in the folder name!",
};

export const fileName = {
  pattern: /^[a-zA-Z0-9-_.]+$/,
  message: "Invalid characters in the file name!",
};

export const required = (condition = true) => ({
  required: condition,
  message: "Required field!",
  transform: (value: any) => value?.trim(),
});

export const url = {
  type: "url" as const,
  message: "Invalid url!",
};

export const email = {
  type: "email" as const,
  message: "The email is not valid!",
};

export const minMax = ({ text, max, min }: minMaxType) => ({
  max,
  min,
  message: !min
    ? `${text} cannot be longer than ${max} characters`
    : `${text} cannot be shorter than ${min} or longer than ${max} characters!`,
  transform: (value: any) => value?.trim(),
});

export const validateKey =
  (ccVarData: TEditModeProps | null, varKeysLowerCase: Array<string>) =>
  (_: any, value: string) => {
    const trimmed = value ? value.trim() : "";
    const lowerCaseValue = trimmed.toLowerCase();
    if (!trimmed.length) {
      return Promise.reject(new Error("Required field!"));
    }
    if (
      ccVarData?.variableData.id.key.toLowerCase() !== lowerCaseValue &&
      varKeysLowerCase.includes(lowerCaseValue)
    ) {
      return Promise.reject(new Error("The key already exists in this grid!"));
    }
    if (!/^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(trimmed)) {
      return Promise.reject(new Error("Invalid Javascript variable name!"));
    }
    return Promise.resolve();
  };

export const microSitePath = {
  pattern: /^[a-zA-Z0-9/.${}_-]+$/,
  message: "Invalid path format",
};

export const validateMicrositeAssetPath: Rule = () => ({
  validator(_, value) {
    const trimmed = value ? value.trim() : "";
    if (!trimmed.endsWith("/")) {
      return Promise.reject(new Error("The string must end with a slash '/'"));
    }
    if (/\/{2,}/.test(trimmed)) {
      return Promise.reject(
        new Error("You can't use multiple slashes in a row"),
      );
    }
    return Promise.resolve();
  },
});
