import { FC } from "react";
import { useSelector } from "react-redux";
import CampaignDocView from "src/components/CampaignDocView";
import { selectCurrentCampaignView } from "src/store/slices/campaignsSlice";
import CampaignGrid from "../../../../components/CampaignGrid/Grid/CampaignGrid";
import CampaignAssets from "../../../../components/campaignAssets/CampaignAssets/CampaignAssets";
import { CampaignViewTypes } from "../../../../types";
import { useParams } from "react-router-dom";
import { useUserHasPermission } from "../../../../hooks/useUserHasPermission";

type Props = {};

const StepData: FC<Props> = () => {
  const { companyId, stepId } = useParams();
  const currentCampaignView = useSelector(selectCurrentCampaignView);
  const { hasAssetListRole } = useUserHasPermission({ companyId });

  if (!stepId) return null;

  return (
    <div className="flex flex-col gap-[24px]">
      <div
        style={{
          display:
            currentCampaignView === CampaignViewTypes.GRID ? "block" : "none",
        }}
      >
        <CampaignGrid />
      </div>

      <div
        style={{
          display:
            currentCampaignView === CampaignViewTypes.DOC ? "block" : "none",
        }}
      >
        <CampaignDocView />
      </div>
      {hasAssetListRole && <CampaignAssets />}
    </div>
  );
};

export default StepData;
