import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  getUserPermissionsApi,
  userLoginApi,
  userLoginWithGoogleApi,
  userLogoutApi,
} from "../../api/user.api";
import handleRequestError, {
  TCustomError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { TUser, TUserMyPermissions } from "../../globalTypes";
import { TSystemPermissions } from "../../types/securityGroupes";
import { getSystemPermissionsApi } from "../../api/permissions-group.api";

export enum UserPermissions {
  ROLE_COMPANY_MICROSITE_USER_LIST = "ROLE_COMPANY_MICROSITE_USER_LIST",
  ROLE_COMPANY_MICROSITE_USER_CREATE = "ROLE_COMPANY_MICROSITE_USER_CREATE",
  ROLE_COMPANY_MICROSITE_USER_DELETE = "ROLE_COMPANY_MICROSITE_USER_DELETE",
  ROLE_COMPANY_MICROSITE_USER_RESET_PWD = "ROLE_COMPANY_MICROSITE_USER_RESET_PWD",
  ROLE_PERM_GROUP_LIST = "ROLE_PERM_GROUP_LIST",
  ROLE_PERM_GROUP_READ = "ROLE_PERM_GROUP_READ",
  ROLE_PERM_GROUP_CREATE = "ROLE_PERM_GROUP_CREATE",
  ROLE_PERM_GROUP_UPDATE = "ROLE_PERM_GROUP_UPDATE",
  ROLE_PERM_GROUP_DELETE = "ROLE_PERM_GROUP_DELETE",
  ROLE_AI_CONFIGURATION_LIST = "ROLE_AI_CONFIGURATION_LIST",
  ROLE_AI_CONFIGURATION_READ = "ROLE_AI_CONFIGURATION_READ",
  ROLE_AI_CONFIGURATION_CREATE = "ROLE_AI_CONFIGURATION_CREATE",
  ROLE_AI_CONFIGURATION_UPDATE = "ROLE_AI_CONFIGURATION_UPDATE",
  ROLE_AI_CONFIGURATION_DELETE = "ROLE_AI_CONFIGURATION_DELETE",
  ROLE_AI_CONFIGURATION_SET_DEFAULT = "ROLE_AI_CONFIGURATION_SET_DEFAULT",
  ROLE_CAMPAIGN_ENV_ITEM_LIST = "ROLE_CAMPAIGN_ENV_ITEM_LIST",
  ROLE_CAMPAIGN_ENV_ITEM_READ = "ROLE_CAMPAIGN_ENV_ITEM_READ",
  ROLE_CAMPAIGN_ENV_ITEM_CREATE = "ROLE_CAMPAIGN_ENV_ITEM_CREATE",
  ROLE_CAMPAIGN_ENV_ITEM_UPDATE = "ROLE_CAMPAIGN_ENV_ITEM_UPDATE",
  ROLE_CAMPAIGN_ENV_ITEM_RENAME = "ROLE_CAMPAIGN_ENV_ITEM_RENAME",
  ROLE_CAMPAIGN_ENV_ITEM_LOOKUP = "ROLE_CAMPAIGN_ENV_ITEM_LOOKUP",
  ROLE_CAMPAIGN_ENV_ITEM_DELETE = "ROLE_CAMPAIGN_ENV_ITEM_DELETE",
  ROLE_CAMPAIGN_ENV_ITEM_IMPORT = "ROLE_CAMPAIGN_ENV_ITEM_IMPORT",
  ROLE_CAMPAIGN_ENV_ITEM_EXPORT = "ROLE_CAMPAIGN_ENV_ITEM_EXPORT",
  ROLE_MS_FS_FILE_LIST = "ROLE_MS_FS_FILE_LIST",
  ROLE_MS_FS_UPLOAD = "ROLE_MS_FS_UPLOAD",
  ROLE_MS_FS_COPY = "ROLE_MS_FS_COPY",
  ROLE_MS_FS_READ = "ROLE_MS_FS_READ",
  ROLE_MS_FS_DELETE = "ROLE_MS_FS_DELETE",
  ROLE_USER_COMPANY_PERM_READ = "ROLE_USER_COMPANY_PERM_READ",
  ROLE_USER_COMPANY_PERM_CREATE = "ROLE_USER_COMPANY_PERM_CREATE",
  ROLE_USER_COMPANY_PERM_DELETE = "ROLE_USER_COMPANY_PERM_DELETE",
  ROLE_DOCUMENT_TEMPLATE_LIST = "ROLE_DOCUMENT_TEMPLATE_LIST",
  ROLE_DOCUMENT_TEMPLATE_IMPORT = "ROLE_DOCUMENT_TEMPLATE_IMPORT",
  ROLE_DOCUMENT_TEMPLATE_CREATE = "ROLE_DOCUMENT_TEMPLATE_CREATE",
  ROLE_DOCUMENT_TEMPLATE_UPDATE = "ROLE_DOCUMENT_TEMPLATE_UPDATE",
  ROLE_DOCUMENT_TEMPLATE_DELETE = "ROLE_DOCUMENT_TEMPLATE_DELETE",
  ROLE_DOCUMENT_TEMPLATE_READ = "ROLE_DOCUMENT_TEMPLATE_READ",
  ROLE_DOCUMENT_TEMPLATE_HTML_2_XSL = "ROLE_DOCUMENT_TEMPLATE_HTML_2_XSL",

  ROLE_CAMPAIGN_LIST = "ROLE_CAMPAIGN_LIST",
  ROLE_CAMPAIGN_CREATE = "ROLE_CAMPAIGN_CREATE",
  ROLE_CAMPAIGN_UPDATE = "ROLE_CAMPAIGN_UPDATE",
  ROLE_CAMPAIGN_DELETE = "ROLE_CAMPAIGN_DELETE",
  ROLE_CAMPAIGN_ARCHIVE = "ROLE_CAMPAIGN_ARCHIVE",
  ROLE_CAMPAIGN_CLONE = "ROLE_CAMPAIGN_CLONE",
  ROLE_CAMPAIGN_PUBLISH = "ROLE_CAMPAIGN_PUBLISH",
  ROLE_CAMPAIGN_UNPUBLISH = "ROLE_CAMPAIGN_UNPUBLISH",
  ROLE_CAMPAIGN_MICROSITE_DEPLOY = "ROLE_CAMPAIGN_MICROSITE_DEPLOY",
  ROLE_CAMPAIGN_START = "ROLE_CAMPAIGN_START",
  ROLE_CAMPAIGN_READ = "ROLE_CAMPAIGN_READ",
  ROLE_CAMPAIGN_EXPORT = "ROLE_CAMPAIGN_EXPORT",
  ROLE_CAMPAIGN_IMPORT = "ROLE_CAMPAIGN_IMPORT",
  ROLE_CAMPAIGN_CHANGE_COMPANY = "ROLE_CAMPAIGN_CHANGE_COMPANY",
  ROLE_CAMPAIGN_UI_SHOW_GRID_PARAMETERS = "ROLE_CAMPAIGN_UI_SHOW_GRID_PARAMETERS",

  ROLE_AI_CONFIGURATION_MANAGER = "ROLE_AI_CONFIGURATION_MANAGER",
  ROLE_PRIVATE_ROOT_FS_FILE_LIST = "ROLE_PRIVATE_ROOT_FS_FILE_LIST",
  ROLE_PRIVATE_ROOT_FS_UPLOAD = "ROLE_PRIVATE_ROOT_FS_UPLOAD",
  ROLE_PRIVATE_ROOT_FS_COPY = "ROLE_PRIVATE_ROOT_FS_COPY",
  ROLE_PRIVATE_ROOT_FS_READ = "ROLE_PRIVATE_ROOT_FS_READ",
  ROLE_PRIVATE_ROOT_FS_DELETE = "ROLE_PRIVATE_ROOT_FS_DELETE",
  ROLE_PRIVATE_FS_FILE_LIST = "ROLE_PRIVATE_FS_FILE_LIST",
  ROLE_PRIVATE_FS_UPLOAD = "ROLE_PRIVATE_FS_UPLOAD",
  ROLE_PRIVATE_FS_COPY = "ROLE_PRIVATE_FS_COPY",
  ROLE_PRIVATE_FS_READ = "ROLE_PRIVATE_FS_READ",
  ROLE_PRIVATE_FS_DELETE = "ROLE_PRIVATE_FS_DELETE",
  ROLE_PHASE_LIST = "ROLE_PHASE_LIST",
  ROLE_PHASE_CREATE = "ROLE_PHASE_CREATE",
  ROLE_PHASE_UPDATE = "ROLE_PHASE_UPDATE",
  ROLE_PHASE_DELETE = "ROLE_PHASE_DELETE",
  ROLE_PHASE_MOVE = "ROLE_PHASE_MOVE",
  ROLE_PHASE_CLONE = "ROLE_PHASE_CLONE",
  ROLE_PHASE_DEPLOY = "ROLE_PHASE_DEPLOY",
  ROLE_PHASE_EXPORT = "ROLE_PHASE_EXPORT",
  ROLE_PHASE_COPY = "ROLE_PHASE_COPY",
  ROLE_PHASE_SHOW_HIDDEN = "ROLE_PHASE_SHOW_HIDDEN",
  ROLE_CAMPAIGN_I18N_LIST = "ROLE_CAMPAIGN_I18N_LIST",
  ROLE_CAMPAIGN_I18N_CREATE = "ROLE_CAMPAIGN_I18N_CREATE",
  ROLE_CAMPAIGN_I18N_UPDATE = "ROLE_CAMPAIGN_I18N_UPDATE",
  ROLE_CAMPAIGN_I18N_DELETE = "ROLE_CAMPAIGN_I18N_DELETE",
  ROLE_CAMPAIGN_I18N_G_CREATE = "ROLE_CAMPAIGN_I18N_G_CREATE",
  ROLE_CAMPAIGN_I18N_G_REPLACE = "ROLE_CAMPAIGN_I18N_G_REPLACE",
  ROLE_USER_SELF_READ = "ROLE_USER_SELF_READ",
  ROLE_USER_LIST = "ROLE_USER_LIST",
  ROLE_USER_READ = "ROLE_USER_READ",
  ROLE_USER_CREATE = "ROLE_USER_CREATE",
  ROLE_USER_UPDATE = "ROLE_USER_UPDATE",
  ROLE_USER_DELETE = "ROLE_USER_DELETE",
  ROLE_USER_COMPANIES_READ = "ROLE_USER_COMPANIES_READ",
  ROLE_USER_COMPANIES_ASSIGN = "ROLE_USER_COMPANIES_ASSIGN",
  ROLE_USER_COMPANIES_UNASSIGN = "ROLE_USER_COMPANIES_UNASSIGN",
  ROLE_USER_DISABLE = "ROLE_USER_DISABLE",
  ROLE_USER_ENABLE = "ROLE_USER_ENABLE",
  ROLE_COMPANY_LIST = "ROLE_COMPANY_LIST",
  ROLE_COMPANY_READ = "ROLE_COMPANY_READ",
  ROLE_COMPANY_CREATE = "ROLE_COMPANY_CREATE",
  ROLE_COMPANY_UPDATE = "ROLE_COMPANY_UPDATE",
  ROLE_COMPANY_DELETE = "ROLE_COMPANY_DELETE",
  ROLE_COMPANY_USERS = "ROLE_COMPANY_USERS",
  ROLE_COMPANY_DISABLE = "ROLE_COMPANY_DISABLE",
  ROLE_COMPANY_ENABLE = "ROLE_COMPANY_ENABLE",
  ROLE_COMPANY_USERS_READ = "ROLE_COMPANY_USERS_READ",
  ROLE_COMPANY_USERS_ASSIGN = "ROLE_COMPANY_USERS_ASSIGN",
  ROLE_COMPANY_USERS_UNASSIGN = "ROLE_COMPANY_USERS_UNASSIGN",
  ROLE_TEMPLATE_FS_FILE_LIST = "ROLE_TEMPLATE_FS_FILE_LIST",
  ROLE_TEMPLATE_FS_UPLOAD = "ROLE_TEMPLATE_FS_UPLOAD",
  ROLE_TEMPLATE_FS_COPY = "ROLE_TEMPLATE_FS_COPY",
  ROLE_TEMPLATE_FS_READ = "ROLE_TEMPLATE_FS_READ",
  ROLE_TEMPLATE_FS_DELETE = "ROLE_TEMPLATE_FS_DELETE",
  ROLE_PUBLIC_FS_FILE_LIST = "ROLE_PUBLIC_FS_FILE_LIST",
  ROLE_PUBLIC_FS_UPLOAD = "ROLE_PUBLIC_FS_UPLOAD",
  ROLE_PUBLIC_FS_COPY = "ROLE_PUBLIC_FS_COPY",
  ROLE_PUBLIC_FS_READ = "ROLE_PUBLIC_FS_READ",
  ROLE_PUBLIC_FS_DELETE = "ROLE_PUBLIC_FS_DELETE",
  ROLE_DICTIONARY_SEARCH = "ROLE_DICTIONARY_SEARCH",
  ROLE_DICTIONARY_CREATE = "ROLE_DICTIONARY_CREATE",
  ROLE_DICTIONARY_DELETE = "ROLE_DICTIONARY_DELETE",
  ROLE_COMPANY_PERM_OVR_LIST = "ROLE_COMPANY_PERM_OVR_LIST",
  ROLE_COMPANY_PERM_OVR_CREATE = "ROLE_COMPANY_PERM_OVR_CREATE",
  ROLE_COMPANY_PERM_OVR_DELETE = "ROLE_COMPANY_PERM_OVR_DELETE",
  ROLE_USER_GLOBAL_PERM_READ = "ROLE_USER_GLOBAL_PERM_READ",
  ROLE_USER_GLOBAL_PERM_CREATE = "ROLE_USER_GLOBAL_PERM_CREATE",
  ROLE_USER_GLOBAL_PERM_DELETE = "ROLE_USER_GLOBAL_PERM_DELETE",
  ROLE_COMPANY_OPTION_LIST = "ROLE_COMPANY_OPTION_LIST",
  ROLE_COMPANY_OPTION_READ = "ROLE_COMPANY_OPTION_READ",
  ROLE_COMPANY_OPTION_UPDATE = "ROLE_COMPANY_OPTION_UPDATE",
  ROLE_STEP_LIST = "ROLE_STEP_LIST",
  ROLE_STEP_CREATE = "ROLE_STEP_CREATE",
  ROLE_STEP_UPDATE = "ROLE_STEP_UPDATE",
  ROLE_STEP_DELETE = "ROLE_STEP_DELETE",
  ROLE_STEP_MOVE = "ROLE_STEP_MOVE",
  ROLE_STEP_CLONE = "ROLE_STEP_CLONE",
  ROLE_STEP_DEPLOY = "ROLE_STEP_DEPLOY",
  ROLE_STEP_EXPORT = "ROLE_STEP_EXPORT",
  ROLE_STEP_TEMPLATE_PREVIEW = "ROLE_STEP_TEMPLATE_PREVIEW",
  ROLE_STEP_COPY = "ROLE_STEP_COPY",
  ROLE_STEP_SHOW_HIDDEN = "ROLE_STEP_SHOW_HIDDEN",
  ROLE_STEP_SHOW_DOC_VIEW = "ROLE_STEP_SHOW_DOC_VIEW",
  ROLE_CAMPAIGN_CC_LIST = "ROLE_CAMPAIGN_CC_LIST",
  ROLE_CAMPAIGN_CC_READ = "ROLE_CAMPAIGN_CC_READ",
  ROLE_CAMPAIGN_CC_GRAPH = "ROLE_CAMPAIGN_CC_GRAPH",
  ROLE_CAMPAIGN_CC_CREATE = "ROLE_CAMPAIGN_CC_CREATE",
  ROLE_CAMPAIGN_CC_UPDATE = "ROLE_CAMPAIGN_CC_UPDATE",
  ROLE_CAMPAIGN_CC_RENAME = "ROLE_CAMPAIGN_CC_RENAME",
  ROLE_CAMPAIGN_CC_DELETE = "ROLE_CAMPAIGN_CC_DELETE",
  ROLE_CAMPAIGN_CC_CLONE = "ROLE_CAMPAIGN_CC_CLONE",
  ROLE_CAMPAIGN_CC_OVERRIDE = "ROLE_CAMPAIGN_CC_OVERRIDE",
  ROLE_CAMPAIGN_CC_UPDATE_STATE = "ROLE_CAMPAIGN_CC_UPDATE_STATE",
  ROLE_CAMPAIGN_CC_SWAP = "ROLE_CAMPAIGN_CC_SWAP",
  ROLE_CAMPAIGN_UI_CC_GET_REFERENCE = "ROLE_CAMPAIGN_UI_CC_GET_REFERENCE",
  ROLE_CAMPAIGN_UI_CC_PROMPT_MODIFICATION = "ROLE_CAMPAIGN_UI_CC_PROMPT_MODIFICATION",

  ROLE_CAMPAIGN_ENV_COMPUTATION = "ROLE_CAMPAIGN_ENV_COMPUTATION",
  ROLE_CAMPAIGN_COMPUTATION_STOP = "ROLE_CAMPAIGN_COMPUTATION_STOP",
  ROLE_CAMPAIGN_CC_ITEM_COMPUTATION = "ROLE_CAMPAIGN_CC_ITEM_COMPUTATION",
  ROLE_CAMPAIGN_COMPUTATION = "ROLE_CAMPAIGN_COMPUTATION",
  ROLE_CAMPAIGN_PHASE_COMPUTATION = "ROLE_CAMPAIGN_PHASE_COMPUTATION",
  ROLE_CAMPAIGN_STEP_COMPUTATION = "ROLE_CAMPAIGN_STEP_COMPUTATION",
  ROLE_PUBLIC_ROOT_FS_FILE_LIST = "ROLE_PUBLIC_ROOT_FS_FILE_LIST",
  ROLE_PUBLIC_ROOT_FS_UPLOAD = "ROLE_PUBLIC_ROOT_FS_UPLOAD",
  ROLE_PUBLIC_ROOT_FS_COPY = "ROLE_PUBLIC_ROOT_FS_COPY",
  ROLE_PUBLIC_ROOT_FS_READ = "ROLE_PUBLIC_ROOT_FS_READ",
  ROLE_PUBLIC_ROOT_FS_DELETE = "ROLE_PUBLIC_ROOT_FS_DELETE",
  ROLE_ASSET_LIST = "ROLE_ASSET_LIST",
  ROLE_ASSET_CREATE = "ROLE_ASSET_CREATE",
  ROLE_ASSET_UPDATE = "ROLE_ASSET_UPDATE",
  ROLE_ASSET_READ = "ROLE_ASSET_READ",
  ROLE_ASSET_DELETE = "ROLE_ASSET_DELETE",
  ROLE_ASSET_APPROVE = "ROLE_ASSET_APPROVE",
  ROLE_ASSET_APPROVE_PUB = "ROLE_ASSET_APPROVE_PUB",
  ROLE_ASSET_DEPLOY = "ROLE_ASSET_DEPLOY",
  ROLE_COMPANY_MICROSITE_READ = "ROLE_COMPANY_MICROSITE_READ",
  ROLE_COMPANY_MICROSITE_CREATE = "ROLE_COMPANY_MICROSITE_CREATE",
  ROLE_COMPANY_MICROSITE_DELETE = "ROLE_COMPANY_MICROSITE_DELETE",
  ROLE_GLOBAL_PERM_OVR_LIST = "ROLE_GLOBAL_PERM_OVR_LIST",
  ROLE_GLOBAL_PERM_OVR_CREATE = "ROLE_GLOBAL_PERM_OVR_CREATE",
  ROLE_GLOBAL_PERM_OVR_DELETE = "ROLE_GLOBAL_PERM_OVR_DELETE",
  ROLE_G18N_LIST = "ROLE_G18N_LIST",
  ROLE_G18N_STAT = "ROLE_G18N_STAT",
  ROLE_G18N_CREATE = "ROLE_G18N_CREATE",
  ROLE_G18N_UPDATE = "ROLE_G18N_UPDATE",
  ROLE_G18N_DELETE = "ROLE_G18N_DELETE",

  ROLE_CAMPAIGN_TEMPLATES_LIST = "ROLE_CAMPAIGN_TEMPLATES_LIST",

  // UI Permissions - used only for frontend
  ROLE_UI_BUILD_INFO = "ROLE_UI_BUILD_INFO",
  ROLE_UI_SHOW_DETAILED_PERMISSIONS_LIST = "ROLE_UI_SHOW_DETAILED_PERMISSIONS_LIST",

  ROLE_TEMPLATE_FS_EDIT = "ROLE_TEMPLATE_FS_EDIT",
  ROLE_PRIVATE_ROOT_FS_EDIT = "ROLE_PRIVATE_ROOT_FS_EDIT",
  ROLE_PRIVATE_FS_EDIT = "ROLE_PRIVATE_FS_EDIT",
  ROLE_MS_FS_EDIT = "ROLE_MS_FS_EDIT",
  ROLE_PUBLIC_FS_EDIT = "ROLE_PUBLIC_FS_EDIT",
  ROLE_PUBLIC_ROOT_FS_EDIT = "ROLE_PUBLIC_ROOT_FS_EDIT",
}

const initialState = {
  userData: {} as TUser,
  isAuthenticated: false,
  permissions: {} as TUserMyPermissions,
  permissionsTree: {} as TSystemPermissions,
};

type InitialStateType = typeof initialState;

const userDataSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserPermissions: (
      state: InitialStateType,
      action: PayloadAction<TUserMyPermissions>,
    ) => {
      state.permissions = action.payload;
    },
    setPermissionsTree: (
      state: InitialStateType,
      action: PayloadAction<TSystemPermissions>,
    ) => {
      state.permissionsTree = action.payload;
    },
    setIsAuthenticated: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state: InitialStateType, action: PayloadAction<TUser>) => {
      state.userData = action.payload;
    },
  },
});

export const {
  setUserPermissions,
  setUser,
  setIsAuthenticated,
  setPermissionsTree,
} = userDataSlice.actions;

export default userDataSlice.reducer;

/* eslint-disable*/
export const getIsAuthenticated = (): boolean =>
  useSelector((state: RootState) => state.userData.isAuthenticated);
export const selectUsersState = (state: RootState): RootState["userData"] =>
  state.userData;
export const selectUserData = createSelector(
  selectUsersState,
  (state) => state.userData,
);
export const selectUserPermissions = createSelector(
  selectUsersState,
  (state): TUserMyPermissions => state.permissions,
);
export const selectPermissionsTree = createSelector(
  selectUsersState,
  (state) => state.permissionsTree,
);

export const loginThunk = createAsyncThunk<
  TUser,
  string | undefined,
  { rejectValue: TCustomError }
>(
  "users/loginThunk",
  async (googleCredentials, { rejectWithValue, dispatch }) => {
    try {
      let user: TUser;

      if (googleCredentials) {
        const { data } = await userLoginWithGoogleApi({
          credential: googleCredentials,
        });
        user = data;
      } else {
        const { data } = await userLoginApi();
        user = data;
      }

      const { data: userPermissions } = await getUserPermissionsApi();
      const { data: permissionsTree } = await getSystemPermissionsApi();

      dispatch(setUserPermissions(userPermissions));
      dispatch(setPermissionsTree(permissionsTree));

      dispatch(setUser(user));
      dispatch(setIsAuthenticated(true));

      return user;
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to login:`, e);

      return rejectWithValue(customError);
    }
  },
);

export const logoutThunk = createAsyncThunk<
  undefined,
  undefined,
  { rejectValue: TCustomError }
>("users/logoutThunk", async (_, { rejectWithValue, dispatch }) => {
  try {
    await userLogoutApi();

    dispatch(setIsAuthenticated(false));
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(`An error occurred while trying to logout:`, e);

    return rejectWithValue(customError);
  }
});
