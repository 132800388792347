import { Drawer, Form, Input, Modal, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import { getMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { minMax, noSlashes, required, url } from "../../../utils/validations";
import { Button } from "../../common/ui/button";

export type TCompanyForm = {
  // logo: string;
  website: string;
  name: string;
  // createdTime: string;
};

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  initialFormValues: TCompanyForm | null;
  onUpdateCompany?: ({
    values,
  }: {
    values: TCompanyForm;
    rebuild: boolean;
  }) => Promise<void>;
  onCreateCompany?: ({ values }: { values: TCompanyForm }) => Promise<void>;
};

const CompanyDrawer: FC<Props> = ({
  visible,
  initialFormValues,
  setVisible,
  onUpdateCompany,
  onCreateCompany,
}) => {
  const messageApi = getMessageApi();
  const [form] = Form.useForm<TCompanyForm>();
  const [isFetching, setIsFetching] = useState(false);
  const isEditMode = !!initialFormValues;
  // const [fileList, setFileList] = useState<Array<UploadFile<any>>>([]);
  // const [imageToShow, setImageToShow] = useState<string | null>(null);

  const resetData = () => {
    form.resetFields();
    // setFileList([]);
    // setImageToShow(null);
  };

  useEffect(() => {
    if (visible) {
      if (initialFormValues) {
        // setImageToShow(initialFormValues.logo);

        form.setFieldsValue({
          name: initialFormValues.name,
          website: initialFormValues.website || "",
        });
      } else {
        resetData();
      }
    }
  }, [initialFormValues, visible]);

  // const fileUploadProps: UploadProps = {
  //   name: "file",
  //   multiple: false,
  //   maxCount: 1,
  //   fileList,
  //   onChange(info) {
  //     setFileList(info.fileList);
  //   },
  //   onRemove(_) {
  //     setImageToShow(null);
  //     setFileList([]);
  //   },
  //   beforeUpload(file) {
  //     setImageToShow(URL.createObjectURL(file));
  //     return false;
  //   },
  // };

  const onCloseDrawer = () => {
    setVisible(false);
  };

  const submitForm = () => {
    return submitFormWithTrim({
      form,
      onSuccessValidationCb: async (values: TCompanyForm) => {
        try {
          setIsFetching(true);

          if (isEditMode && onUpdateCompany) {
            const hasCompanyNameChanged =
              initialFormValues?.name !== values.name;

            if (hasCompanyNameChanged) {
              Modal.confirm({
                title: "Confirm company update",
                content:
                  "Company name has changed, do you want to republish resources?",
                okText: "Confirm",
                cancelText: "Cancel",
                onOk: () => onUpdateCompany({ values, rebuild: true }),
              });
            } else {
              await onUpdateCompany({ values, rebuild: false });
            }
          } else {
            onCreateCompany && (await onCreateCompany({ values }));
          }
        } catch (e: any) {
          const customError = handleRequestError(e);

          messageApi.error(customError.message);
          console.error(customError);
        } finally {
          setIsFetching(false);
        }
      },
    });
  };

  return (
    <Drawer
      title={
        <div className="font-sans text-white text-base">
          {isEditMode ? initialFormValues.name : "Create Company"}
        </div>
      }
      placement="right"
      onClose={onCloseDrawer}
      open={visible}
      styles={{
        header: { background: "#0D0A37", borderBottom: "0" },
        footer: { borderTop: "1px solid #CBD5E1", paddingLeft: "40px" },
        body: { padding: "40px" },
      }}
      width={533}
      footer={
        <div className="flex justify-start gap-[8px]">
          <Button
            className="rounded-full"
            onClick={submitForm()}
            disabled={isFetching}
          >
            {isEditMode ? "Save" : "Create"}
          </Button>
          <Button
            className="border border-gray-600 text-gray-600 w-[94px]"
            variant="primaryOutline"
            onClick={onCloseDrawer}
          >
            Cancel
          </Button>
        </div>
      }
    >
      <Spin spinning={isFetching}>
        <Form
          form={form}
          layout="vertical"
          name="company_form"
          initialValues={initialFormValues || undefined}
        >
          {/*<Form.Item*/}
          {/*  name="logo"*/}
          {/*  label={*/}
          {/*    <span className="font-sans font-bold text-[#475569] text-[14px]">*/}
          {/*      Logo*/}
          {/*    </span>*/}
          {/*  }*/}
          {/*  className="w-[152px]"*/}
          {/*>*/}
          {/*  <Upload.Dragger {...fileUploadProps} className="redesign">*/}
          {/*    <div className="flex flex-col items-center justify-center gap-[12px] w-full h-[152px]">*/}
          {/*      {imageToShow ? (*/}
          {/*        <img*/}
          {/*          src={imageToShow}*/}
          {/*          alt="Uploaded"*/}
          {/*          className=""*/}
          {/*          width={120}*/}
          {/*          height={120}*/}
          {/*        />*/}
          {/*      ) : (*/}
          {/*        <>*/}
          {/*          <p className="">*/}
          {/*            <UploadIcon className="w-[30px] h-[30px] text-[#4F46E5]" />*/}
          {/*          </p>*/}
          {/*          <p className="font-sans font-normal text-[#475569] text-[12px]">*/}
          {/*            Drag or select*/}
          {/*            <br /> an image to upload*/}
          {/*          </p>*/}
          {/*        </>*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  </Upload.Dragger>*/}
          {/*</Form.Item>*/}
          <Form.Item
            name="name"
            label={
              <span className="font-sans font-bold text-[#475569] text-[14px]">
                Company Name
              </span>
            }
            tooltip="Please enter a value between 2 and 255 characters. Slashes are prohibited."
            rules={[
              required(),
              minMax({ text: "The company name", min: 2, max: 255 }),
              noSlashes,
            ]}
          >
            <Input size="large" placeholder="Enter unique company name" />
          </Form.Item>

          <Form.Item
            name="website"
            label={
              <span className="font-sans font-bold text-[#475569] text-[14px]">
                Company URL
              </span>
            }
            rules={[required(), url, minMax({ text: "Url", max: 255 })]}
          >
            <Input size="large" placeholder="Enter a valid company website" />
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default CompanyDrawer;
