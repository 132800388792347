import React, { FC, useEffect, useState } from "react";
import { Button } from "../common/ui/button";
import { Icons } from "../common/Icons";
import TextArea from "antd/es/input/TextArea";
import { updateCcVarApi } from "../../api/cc-variables.api";
import { TCcVariable } from "../../store/slices/ccVariablesSlice";
import handleRequestError from "../../utils/handleRequestError";
import { getMessageApi } from "../../store/slices/appSlice";
import { useUserHasPermission } from "../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

type Props = {
  ccItem: TCcVariable;
  onAfterResultUpdate?: (updatedCCVariable: TCcVariable) => void | Promise<void>;
  setActiveOverrideTab: () => void;
};

const PaneResult: FC<Props> = ({
  ccItem,
  onAfterResultUpdate,
  setActiveOverrideTab,
}) => {
  const { id, type, result, modifiedTime } = ccItem;
  const messageApi = getMessageApi();
  const [isFetching, setIsFetching] = useState(false);
  const [newResult, setNewResult] = useState<string>(ccItem.result || "");
  const { companyId } = useParams();
  const { hasCampaignCcOverrideRole, hasCampaignCcUpdateRole } =
    useUserHasPermission({ companyId });

  useEffect(() => {
    setNewResult(ccItem.result || "");
  }, [ccItem]);

  const onCreate = async () => {
    try {
      setIsFetching(true);

      const values = {
        key: id.key,
        type,
        result: newResult,
        modifiedTime,
      };

      const {data} = await updateCcVarApi({
        ccItem,
        values,
        rebuild: false,
      });

      onAfterResultUpdate && (await onAfterResultUpdate(data));

      messageApi.success("The result was successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const resultElement = (
    <div className="flex-grow overflow-y-auto">
      {result || "There is no value in the item"}
    </div>
  );

  return (
    <div>
      {type !== "string" ? (
        <div>
          {resultElement}

          {hasCampaignCcOverrideRole && (
            <Button
              onClick={setActiveOverrideTab}
              icon={Icons.edit}
              size="sm"
              variant="primaryGhost"
              className="rounded-full"
            >
              Edit
            </Button>
          )}
        </div>
      ) : hasCampaignCcUpdateRole ? (
        <div className="flex-grow overflow-y-auto">
          <TextArea
            value={newResult}
            onChange={(e) => setNewResult(e.target.value)}
            placeholder="Enter result"
            allowClear
            disabled={isFetching}
            rows={4}
          />
          <Button
            className="mt-3 rounded-full"
            onClick={onCreate}
            size="s"
            disabled={newResult === result}
            loading={isFetching}
          >
            Save
          </Button>
        </div>
      ) : (
        resultElement
      )}
    </div>
  );
};

export default PaneResult;
