import { useEffect, useState } from "react";
import handleRequestError from "../../../utils/handleRequestError";
import { TUserPermissionGroupFormValues } from "../../../types/securityGroupes";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  createPermissionGroupApi,
  getPermissionGroupByIdApi,
  updatePermissionGroupApi,
} from "../../../api/permissions-group.api";
import {
  selectPermissionsTree,
  UserPermissions,
} from "../../../store/slices/userDataSlice";
import { useForm } from "antd/es/form/Form";

const useGetSecurityGroupData = () => {
  const { securityGroupId } = useParams<{
    securityGroupId: string | undefined;
  }>();
  const navigate = useNavigate();
  const [form] = useForm<TUserPermissionGroupFormValues>();
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [initialValues, setInitialValues] =
    useState<TUserPermissionGroupFormValues>({ title: "", level: 10 });
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [checkedGlobalPermissions, setCheckedGlobalPermissions] = useState<
    UserPermissions[]
  >([]);
  const [checkedCompanyPermissions, setCheckedCompanyPermissions] = useState<
    UserPermissions[]
  >([]);
  const permissionsTree = useSelector(selectPermissionsTree);

  useEffect(() => {
    getSystemPermissions();
  }, []);

  const navigateToGroupList = () => navigate("/security-groups");
  const getSystemPermissions = async () => {
    try {
      if (securityGroupId) {
        const { data } = await getPermissionGroupByIdApi({
          permissionGroupId: securityGroupId,
        });
        const { title, superUser, level } = data.permissionsGroup;

        setInitialValues({ title, level });

        if (superUser) {
          setIsSuperUser(superUser);
          //set all permissions if superuser
          setCheckedCompanyPermissions(
            Object.values(permissionsTree.companyLevel).flat(),
          );
          setCheckedGlobalPermissions(
            Object.values(permissionsTree.globalLevel).flat(),
          );
        } else {
          // Separate permissions based on their presence in permissionsTree
          const globalPermissions = data.permissions.filter((permission) =>
            Object.values(permissionsTree.globalLevel)
              .flat()
              .includes(permission),
          );

          const companyPermissions = data.permissions.filter((permission) =>
            Object.values(permissionsTree.companyLevel)
              .flat()
              .includes(permission),
          );

          setCheckedCompanyPermissions(companyPermissions);
          setCheckedGlobalPermissions(globalPermissions);
        }
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);

      navigateToGroupList();
    } finally {
      setIsInitialized(true);
    }
  };

  const onSaveData = async (values: TUserPermissionGroupFormValues) => {
    try {
      setIsFetching(true);
      const allPermissions = [
        ...checkedGlobalPermissions,
        ...checkedCompanyPermissions,
      ];

      // remove unnecessary strings (permission group title) when clicked check the entire group
      const filteredPermissions = allPermissions.filter((permission) =>
        permission.startsWith("ROLE_"),
      );

      if (securityGroupId) {
        await updatePermissionGroupApi({
          permissions: filteredPermissions,
          permissionsGroup: {
            id: securityGroupId,
            title: values.title,
            level: values.level,
          },
        });
      } else {
        await createPermissionGroupApi({
          permissions: filteredPermissions,
          permissionsGroup: {
            title: values.title,
            level: values.level
          },
        });
      }

      if (!securityGroupId) {
        navigateToGroupList();
      }
      messageApi.success("The group was successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isInitialized,
    permissionsTree,
    initialValues,
    securityGroupId,
    checkedGlobalPermissions,
    checkedCompanyPermissions,
    setCheckedCompanyPermissions,
    setCheckedGlobalPermissions,
    isSuperUser,
    isFetching,
    form,
    onSaveData,
    navigateToGroupList,
  };
};

export default useGetSecurityGroupData;
