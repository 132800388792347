import React, { FC, ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useUserHasPermission } from "../hooks/useUserHasPermission";
import { UserPermissions } from "../store/slices/userDataSlice";
import ForbiddenPage from "../pages/ForbiddenPage";

type Props = {
  children: ReactElement;
  permission: UserPermissions;
  isCompanyRole?: boolean;
  isAnywhereRole?: boolean;
};

const ProtectedByRoleRoute: FC<Props> = ({
  children,
  permission,
  isCompanyRole,
  isAnywhereRole,
}) => {
  const { companyId } = useParams();
  const { hasPermission, hasPermissionAnywhere } = useUserHasPermission({
    companyId: isCompanyRole ? companyId : undefined,
  });
  const allowed = isAnywhereRole
    ? hasPermissionAnywhere(permission)
    : hasPermission(permission);

  if (!allowed) {
    return <ForbiddenPage />;
  }

  return children;
};

export default ProtectedByRoleRoute;
