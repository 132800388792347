import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { stringifyObjectValues } from "src/utils/stringifyObjectValues";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  initCampaignsListFilters,
  loadCampaignsList,
} from "../../../store/slices/campaignsListSlice";
import { AppDispatch } from "../../../store/store";
import { ArchiveStatus, TCampaignListOptionType } from "src/globalTypes";

export const ALL_COMPANIES_ID = "ALL";
export const ALL_TEMPLATES_ID = "DRAFT_TEMPLATE,PUBLISHED_TEMPLATE";

export type CampaignsListRequestParams = {
  companyId: number | null;
  campaignType: TCampaignListOptionType;
  page: number;
  pageSize: number;
  archive: ArchiveStatus;
};

export type CampaignsListAdditionalParams = {
  backUrl?: string;
};
// TODO: refactor https://github.com/VelocityEngineAdmin/ElevateApp/pull/579#discussion_r1768241980
const useCampaignsList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isTemplatesPage = useLocation().pathname.includes("/templates");
  const messageApi = getMessageApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const additionalParams = useMemo(() => {
    const backUrl = searchParams.get("backUrl");
    if (!backUrl) {
      return {};
    }
    return {
      backUrl: decodeURIComponent(backUrl),
    };
  }, [searchParams]);

  const requestParams = useMemo<CampaignsListRequestParams>(() => {
    const page = searchParams.get("page");
    const pageSize = searchParams.get("pageSize");
    const companyId = searchParams.get("companyId");
    const campaignType = searchParams.get(
      "campaignType",
    ) as TCampaignListOptionType;
    const archive = searchParams.get("archive");

    return {
      companyId: companyId ? Number(companyId) : null,
      campaignType,
      page: Number(page),
      pageSize: Number(pageSize),
      archive: archive === "true" ? true : archive === "false" ? false : "all",
    };
  }, [searchParams]);

  const init = () => {
    try {
      dispatch(initCampaignsListFilters());
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        "An error occurred while trying to init campaigns page:",
        e,
      );
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (
      requestParams.page &&
      requestParams.pageSize &&
      requestParams.campaignType
    ) {
      requestCampaignsList(requestParams);
    } else {
      updateSearchParams({
        ...requestParams,
        campaignType:
          requestParams.campaignType ||
          (isTemplatesPage ? ALL_TEMPLATES_ID : "CAMPAIGN"),
        page: requestParams.page || 1,
        pageSize: requestParams.pageSize || 50,
      });
    }
  }, [requestParams]);

  async function requestCampaignsList(
    requestParams: CampaignsListRequestParams,
  ) {
    try {
      await dispatch(loadCampaignsList(requestParams)).unwrap();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        "An error occurred while trying to init campaigns page:",
        e,
      );
    }
  }

  const updateSearchParams = (
    requestParams: CampaignsListRequestParams,
    additionalParams?: CampaignsListAdditionalParams,
  ) => {
    const stringifiedParams = stringifyObjectValues<
      CampaignsListRequestParams & CampaignsListAdditionalParams
    >({
      ...requestParams,
      ...additionalParams,
    });
    setSearchParams(stringifiedParams);
  };
  const handleChangePage = (page: number) => {
    updateSearchParams(
      {
        ...requestParams,
        page,
      },
      additionalParams,
    );
  };
  const handleChangePageSize = (pageSize: number) => {
    updateSearchParams(
      {
        ...requestParams,
        pageSize,
        page: 1,
      },
      additionalParams,
    );
  };
  const handleChangeCompany = (companyId: string) => {
    updateSearchParams(
      {
        ...requestParams,
        companyId:
          companyId === ALL_COMPANIES_ID || !companyId
            ? null
            : Number(companyId),
        page: 1,
      },
      additionalParams,
    );
  };
  const handleChangeCampaignType = (campaignType: TCampaignListOptionType) => {
    updateSearchParams(
      {
        ...requestParams,
        campaignType,
        page: 1,
      },
      additionalParams,
    );
  };
  const handleChangeArchive = (archive: ArchiveStatus) => {
    updateSearchParams({ ...requestParams, archive }, additionalParams);
  };
  return {
    requestParams,
    additionalParams,
    requestCampaignsList,
    handleChangePage,
    handleChangePageSize,
    handleChangeCompany,
    handleChangeCampaignType,
    handleChangeArchive,
  };
};
export default useCampaignsList;
